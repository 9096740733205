import React, { useReducer } from "react"
import { Device } from "../types/dataTypes"

interface ITestState {
  devices: Device[]
  authenticated: boolean
}

interface IAction {
  payload: any
  type: string
}

interface IStore {
  state: ITestState
  dispatch: React.Dispatch<IAction>
}

const initialState: IStore = {
  state: {
    devices: [],
    authenticated: false,
  },
  dispatch: () => {},
}

export const Store = React.createContext<IStore>(initialState)

function reducer(state: ITestState, action: IAction): ITestState {
  switch (action.type) {
    case "FETCH_DEVICES":
      return { ...state, devices: action.payload }
    case "AUTHENTICATED_TRUE":
      console.log("Current authenticated state: ", state.authenticated)
      return { ...state, authenticated: true }
    case "AUTHENTICATED_FALSE":
      console.log("Current authenticated state: ", state.authenticated)
      return { ...state, authenticated: false }
    default:
      return state
  }
}

export function StoreProvider(props: any): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialState.state)
  return (
    <Store.Provider value={{ state, dispatch }}>
      {props.children}{" "}
    </Store.Provider>
  )
}
