import React, { useEffect, useState } from "react"
import { Routes, Route, Navigate } from "react-router-dom"
import {
    getAccount,
    getAssets,
    getBuildings,
    getDeviceDeployments,
    getDeviceLocations,
    getDevices,
    getRooms
} from "../api/api"
import Sidebar from "../components/Sidebar"
import Account from "./Account/Account"
import Devices from "./Devices"
import ConditionMonitoring from "./ConditionMonitoring"
import AlarmDashboard from "./AlarmDashboard"
import NotFound from "./NotFound"
import { SidebarElement, TempState } from "../types/componentTypes"
import {
    Asset,
    Building,
    Device,
    DeviceDeployment,
    DeviceLocation,
    Organization,
    Room,
    UserAccount
} from "../types/dataTypes"
import ProtectedRoute from "./ProtectedRoute"
import GrafanaWrapper from "../components/Grafana"

// Old pages
import DeviceSettings from "../old/components/sensors/DeviceSettings"
import RegisterDevice from "../old/components/sensors/RegisterDevice"
import MultipleDeviceDashboard from "../old/components/server/routes/MultipleDeviceDashboard"
import "bootstrap/dist/css/bootstrap.css"
import ConditionMonitoringGrafanaDashboard from "./ConditionMonitoringGrafanaDashboard";
import Alarm from "./Alarm";
import AlarmOverview from "./AlarmOverview";

// Main acts as a wrapper for all pages

export default function Main() {
  const [state, setState] = useState<TempState>({
    devices: [],
    soundlevels: {},
    organizations: [],
    buildings: [],
    rooms: [],
    assets: [],
    deviceDeployments: []
  })

  useEffect(() => {
    updateState()
    return
  }, [])

  const updateState = async () => {
    const devices: Device[] = await getDevices().catch(console.log)

    if (devices.length === 0) {
      return
    }

    let orgs: Organization[] = []
    devices &&
      devices.map(device => {
        const id = device.organization ? device.organization.id : null
        if (!orgs.find(o => o.id === id) && id != null) {
          orgs.push(device.organization!)
        }
        return orgs
      })

    let orgIds: String[] = orgs.map( o => o.id)

    const buildings: Building[] = await getBuildings([]).catch(console.log)
    const rooms: Room[] = await getRooms([], []).catch(console.log)
    const assets: Asset[] = await getAssets([], [], []).catch(console.log)
    const deviceDeployments: DeviceDeployment[] = await getDeviceDeployments([], [], [], [], null, null).catch(console.log);
    const userAccount: UserAccount = await getAccount().catch(console.log)

    let tempState: TempState = {
        devices: devices,
        soundlevels: {},
        organizations: orgs,
        buildings: buildings,
        rooms: rooms,
        assets: assets,
        deviceDeployments: deviceDeployments,
        userAccount
    }

    setState(tempState)
  }


  return (
    <>
      <Sidebar state={state}></Sidebar>
      <div className="tw-h-screen sm:tw-ml-[80px] 2xs:tw-ml-0 md:tw-mt-0 tw-relative tw-bg-primary-off-white">
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute key={0}
                outlet={<ConditionMonitoring state={state} />}
              />
            }
          />

          <Route
            path="/account"
            element={
              <ProtectedRoute key={1}
                outlet={<Account />}
              />
            }
          />

          <Route
            path="/sensor/:sensor_id"
            element={
              <ProtectedRoute key={2}
                outlet={<Navigate to="/" />}
              />
            }
          />
          <Route
            path="/device/:device_id"
            element={
              <ProtectedRoute key={3}
                outlet={<GrafanaWrapper state={state} />}
              />
            }
          />
          <Route
            path="/device/:device_id/settings"
            element={
              <ProtectedRoute key={4}
                outlet={
                  <DeviceSettings state={state} updateState={updateState} />
                }
              />
            }
          />

          <Route
            path="/registerdevice"
            element={
              <ProtectedRoute key={5}
                outlet={<RegisterDevice />}
              />
            }
          />
          <Route
            path="/devices"
            element={
              <ProtectedRoute key={6}
                outlet={<Devices state={state} />}
              />
            }
          />
          <Route
            path="/cm-dashboard/:device_id/:location_id"
            element={
              <ProtectedRoute key={7}
                outlet={<ConditionMonitoringGrafanaDashboard />}
              />
            }
          />
          <Route
            path="/devices/:org_id?/:device_id?"
            element={
              <ProtectedRoute key={8}
                outlet={<MultipleDeviceDashboard />}
              />
            }
          />
          <Route
            path="/alarmdashboard"
            element={
              <ProtectedRoute key={9}
                outlet={<AlarmDashboard state={state}/>}
              />
            }
          />
          <Route
            path="/alarm/:alarm_id"
            element={
              <ProtectedRoute key={10}
                outlet={<Alarm state={state} />}
              />
            }
          />
          <Route
            path="/alarm"
            element={
              <ProtectedRoute key={11}
                outlet={<AlarmOverview state={state} />}
              />
            }
          />

          <Route path="*" element={
            <ProtectedRoute key={12}
              outlet={<NotFound />}
            />
          }
          />
        </Routes>
      </div>
    </>
  )
}
