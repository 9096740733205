import React from "react"

type TitlebarProps = {
  headline: string
  subHeadline?: string
  showBottomBorder?: boolean
}

const Titlebar = React.forwardRef<HTMLInputElement, TitlebarProps>(
  ({ headline, subHeadline, showBottomBorder = true }: TitlebarProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <div className={`2xs:tw-py-6 sm:tw-py-10 tw-pl-10 ${showBottomBorder ? "tw-border-b tw-border-secondary-darker-off-white" : ""}`} ref={ref}>
        <h1 className="2xs:tw-text-24 2xs:tw-leading-24 sm:tw-text-32 sm:tw-leading-32 tw-font-bold tw-pr-10">
          {headline}
        </h1>
        {subHeadline && <p className="tw-pr-10" role="doc-subtitle" >
          {subHeadline}
        </p>}
      </div>
    )
  }
)

export default Titlebar;