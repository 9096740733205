import React from "react";

type TrafficLightProps = {
    status: string
}

/* General trafic light, not dependent on selection of device */

export default function TrafficLight({
                                         status
                                     }: TrafficLightProps) {

    const statusColorMap: Record<string, string> = {
        UNKNOWN: "tw-bg-status-unknown",
        OK: "tw-bg-status-success",
        WARNING: "tw-bg-status-warning",
        CRITICAL: "tw-bg-status-danger",
    }

    return (
        <div className="tw-w-6 tw-h-6 tw-ml-2.5">
            <span
                className={`tw-relative tw-inline-flex tw-rounded-full tw-h-6 tw-w-6 ${
                    status
                        ? statusColorMap[status]
                        : statusColorMap["UNKNOWN"]
                }`}
            />
        </div>
    )
}