import { TempState } from "../../types/componentTypes";
import { useNavigate } from "react-router-dom";
import Titlebar from "../../components/Titlebar";
import Button from "../../components/Button";
import LazyLoad from "react-lazyload";
import { Device, Organization } from "../../types/dataTypes"
import DeviceListing from "./components/DeviceListing";
import DropdownWrapper from "../../components/DropdownWrapper";
import Search from "../../components/Search";
import Header from "../../components/Header";
import React, { useState } from "react";

type DevicesProps = {
    state: TempState
}

export default function Devices({
    state,
}: DevicesProps) {
    const [search, setSearch] = useState("")

    let navigate = useNavigate();
    let devicesByOrganization: { [index: string]: Device[] } = {}
    let organizations: { [index: string]: Organization | undefined } = {}

    type compareOrgsType = [string, any]

    const compareOrgs = (cotA: compareOrgsType, cotB: compareOrgsType): number => {
        let a = cotA[0]
        let b = cotB[0]

        if (a === "unknown" && b === "unknown") {
            return 0
        }
        if (a === "unknown") {
            return 1
        }
        if (b === "unknown") {
            return -1
        }
        return String(organizations[a]?.name).localeCompare(
            String(organizations[b]?.name)
        )
    }


    const handleSearch = (event: any) => {
        setSearch(event.target.value)
    }

    //TODO: Pull out to common function
    const stringThatShouldNotMatchAnySaneSearch = "LONG UNSEARCHABLE STRING THAT DOES NOT MATCH ------------------"
    const filterDevicesBySearch = () => {
        const searchRegex = new RegExp(search, "i") // 'i' means ignore case
        return state.devices.filter(item => {
            const orgName = item.organization?.name ?? "unknown"
            let deviceDeployment = state.deviceDeployments?.find(dd => dd.device_id == item.id)
            if(deviceDeployment){
                let d_room = state.rooms?.find(r => r.id === deviceDeployment?.room_id)
                let d_building = state.buildings?.find(b => b.id === d_room?.building_id)
                return (
                    searchRegex.test(d_building?.address ?? stringThatShouldNotMatchAnySaneSearch) ||
                    searchRegex.test(d_building?.name ?? stringThatShouldNotMatchAnySaneSearch) ||
                    searchRegex.test(d_room?.name ?? stringThatShouldNotMatchAnySaneSearch) ||
                    searchRegex.test(d_room?.nice_name ?? stringThatShouldNotMatchAnySaneSearch) ||
                    searchRegex.test(item?.serial) ||
                    searchRegex.test(item?.id) ||
                    searchRegex.test(item?.type?.brand ?? stringThatShouldNotMatchAnySaneSearch) ||
                    searchRegex.test(item?.type?.model ?? stringThatShouldNotMatchAnySaneSearch) ||
                    searchRegex.test(orgName!) ||
                    deviceDeployment.assets.some(a => { return(
                        searchRegex.test(a.name) ||
                        searchRegex.test(a.id) ||
                        searchRegex.test(a.nice_name ?? stringThatShouldNotMatchAnySaneSearch) ||
                        searchRegex.test(a.model)
                    )
                    })
                )
            } else {
                return (searchRegex.test(item?.serial) || searchRegex.test(orgName!))
            }
        })
    }

    if (state.devices) {
        for (const device of filterDevicesBySearch()) {
            let id: string = device.organization?.id ?? "unknown"
            let found: Device[] = devicesByOrganization[id]
            if (found && found.length > 0) {
                found.push(device)
            } else {
                devicesByOrganization[id] = [device]
                organizations[id] = device.organization
            }

        }
    }

    return (
        <div>
            <Titlebar headline="Devices" />
            <Header headline="All devices and sensors">
                <Search handleSearch={handleSearch}></Search>
                <div className={"tw-flex tw-pb-1"}>
                    <Button type="button" onClick={() => navigate("/registerdevice")} size={"medium"} styles={"tw-ml-10"}>
                        Add Device
                    </Button>
                </div>
            </Header>
            <div className="tw-flex tw-flex-row tw-flex-nowrap">
                <div className="tw-flex-auto tw-overflow-hidden">
                    {state &&
                        state.organizations &&
                        Object.entries(devicesByOrganization) //Object.entries converts null to "null", for some reason
                            .sort(compareOrgs)
                            .map(function ([orgId, devices]: any, index: number) {
                                return (
                                    <div key={index}>
                                        <LazyLoad height={100} once={true} offset={100}>
                                            <DropdownWrapper
                                                title={organizations[orgId]?.name ?? orgId}
                                                defaultOpen={index === 0 ? true : false}
                                            >
                                                <DeviceListing devices={devices}
                                                               deviceDeployments={state.deviceDeployments}
                                                               buildings={state.buildings}
                                                               rooms={state.rooms}
                                                               organization={organizations[orgId]} />
                                            </DropdownWrapper>
                                        </LazyLoad>
                                    </div>
                                )
                            })}
                </div>
            </div>
        </div>
    )
}