import React from "react"
import { useNavigate } from "react-router"
import { SidebarElement } from "../../types/componentTypes"

type LogoProps = {
  icon?: string
  styles?: string
  aligned?: boolean
  firstElement?: SidebarElement
}

export default function Logo({
  icon,
  styles,
  aligned,
  firstElement,
}: LogoProps) {
  let navigate = useNavigate()

  const onClick = () => {
    if (firstElement) {
      navigate("/")
    }
  }

  return (
    <div
      className={`${styles} ${
        aligned ? "tw-items-center tw-justify-center" : ""
      } tw-cursor-pointer`}
      onClick={() => onClick()}
    >
      <img src={icon ? icon : "logo_dark_icon.svg"} alt="logo" />
    </div>
  )
}
