import React, { useEffect, useReducer, useState } from "react"
import { getDeviceTypes, registerDevice } from "../../../api/api"
import { Card, Col, Container, Row } from "react-bootstrap"
import Select from "react-select"
import { useNavigate } from "react-router-dom"
import RegisterDeviceForm from "./RegisterDeviceForm"
import RegisterDeviceAlert from "./RegisterDeviceAlert"
import RegisterDeviceAdvancedButtonGroup from "./RegisterDeviceAdvancedButtonGroup"

export default () => {
  // --State and constants

  const [deviceTypes, setDeviceTypes] = useState([])
  const [selectedType, setSelectedType] = useState({
    value: "dummy-value",
    label: "",
  }) //Avoids react controlled/uncontrolled warning
  const [validated, setValidated] = useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState({})
  const [advanced, setAdvanced] = useState(false)

  const formReducer = (state, event) => {
    return { ...state, [event.name]: event.value }
  }
  const [formData, setFormData] = useReducer(formReducer, {})

  let navigate = useNavigate()

  const formButtonOptions = [
    { name: "Basic", value: "1", option: false },
    { name: "Advanced", value: "2", option: true },
  ]

  // --Hooks

  useEffect(() => {
    setLoading(true)
    const fetchData = async () => {
      await getDeviceTypes().then(data => {
        const filteredData = data.filter(type => type.brand !== "Soundsensing") //Our own devices are registered in other ways, don't show them here.
        const formatedData = filteredData.map(value => ({
          value: value.id,
          label: value.model,
        }))
        setDeviceTypes(formatedData)
        setSelectedType(formatedData[0])
        setLoading(false)
      })
    }
    fetchData()
  }, [])

  // --Handlers

  function handleTypeSelected(selected) {
    setSelectedType(selected)
  }

  function handleChange(event) {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    })
  }

  async function handleSubmit(event) {
    const form = document.getElementById("device-form")
    if (form.checkValidity()) {
      event.preventDefault()

      setLoading(true)
      const deviceAttributes = Object.assign(
        {},
        { type_id: selectedType.value },
        formData
      )
      const response = await registerDevice(deviceAttributes)
      await setLoading(false)

      if (response.ok) {
        setAlert({
          variant: "success",
          content: "Successfully created new device",
        })
      } else {
        setAlert({
          variant: "danger",
          content: `Oh no, something went wrong. Request failed with status ${response.status}. Please try again or contact us.`,
        })
      }
      //Scroll to alert when shown
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      })
    } else {
      event.preventDefault()
      event.stopPropagation()
    }
    setValidated(true)
  }

  function navigateSuccess() {
    navigate("/")
    navigate(0) //Force refresh for an updated list of devices (not the cached one)
  }

  function clearForm() {
    document.getElementById("device-form").reset()
    setAlert({})
    setValidated(false)
  }

  //Helpers

  function isEmpty(alert) {
    return Object.keys(alert).length === 0 && alert.constructor === Object
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1 className="pt-5 pb-2"> Register New Device </h1>
        </Col>
      </Row>
      {isEmpty(alert) ? (
        ""
      ) : (
        <Row lg={12}>
          <Col>
            <RegisterDeviceAlert
              alert={alert}
              clearForm={clearForm}
              navigateSuccess={navigateSuccess}
            />
          </Col>
        </Row>
      )}
      <Card>
        <Card.Header>
          <Row>
            <Col md={423}>
              <RegisterDeviceAdvancedButtonGroup
                formButtonOptions={formButtonOptions}
                advanced={advanced}
                setAdvanced={setAdvanced}
              />
            </Col>
            <Col className="align-items-md-center">
              <p> Advanced settings are optional </p>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row md={6}>
            <Col>
              <p>Device Type:</p>
            </Col>
            <Col md={4}>
              <Select
                name="select-type"
                isLoading={loading}
                options={deviceTypes}
                value={selectedType}
                onChange={handleTypeSelected}
              />
            </Col>
            <Col md={6}>
              <p>Device type you are looking for not here? Contact us!</p>
            </Col>
          </Row>
          <Row md={6}>
            <Col md={12}>
              <RegisterDeviceForm
                validated={validated}
                advanced={advanced}
                loading={loading}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  )
}
