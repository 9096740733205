import React, { useState, useEffect, useRef } from "react";
import Titlebar from "../../components/Titlebar";
import GrafanaDummy from "./GrafanaDummy";
import { debounce } from "../../functions/general"
import Button from "../Button"
import LinkButton from "../Button/Button"
import { Gear, QuestionCircle, Download, ArrowLeft, XCircle } from "react-bootstrap-icons"
import { useNavigate, useParams, useNavigationType } from "react-router-dom"
import GrafanaTerminology from "./GrafanaTerminology"
import Grafana from "../../old/components/Grafana";
import { ExportCSV } from "../../old/components/ExportCSV";
import { TempState } from "../../types/componentTypes"
import { Device, DeviceType } from "../../types/dataTypes"


type GrafanaWrapperProps = {
    state: TempState
}
//TODO: Get breakpoint from tailwind config
const mdScreenBreakpoint = 768;

//TODO: Collect navigation buttons in to a custom drop down on mobile devices
export default function GrafanaWrapper(
    { state }: GrafanaWrapperProps
) {
    const [windowWidth, setWindowWidth] = useState(0)
    const [bannerHeight, setBannerHeight] = useState(0)
    const bannerContainerRef = useRef<any>(null);
    const navigate = useNavigate()
    const navigationType = useNavigationType()
    const [showInfo, setShowInfo] = useState(false);
    const [showExport, setShowExport] = useState(false);
    const [device, setDevice] = useState<Device | null>(null)
    const [deviceNickname, setDeviceNickname] = useState<string>("")

    const params = useParams() // Key-value-pairs of dynamic params from current URL

    useEffect(() => {
        const deviceId = params.device_id // Extract device id from URL params
        if (Array.isArray(state.devices) && state.devices.length !== 0 && deviceId) {
            const newDevice: Device = state.devices[state.devices.findIndex(device => device.id === deviceId)]; //Get device from parent state
            setDevice(newDevice);
        }
    }, [state])

    useEffect(() => {
        if (device) {
            //Format device name based on whether it has a nickname
            const newDeviceName = device?.nickname
                ? `${device?.nickname} (Serial ${device?.serial})`
                : `Serial ${device.serial}`;
            setDeviceNickname(newDeviceName);
        }
    }, [device])




    const updateDimensions = () => {
        setBannerHeight(bannerContainerRef.current.clientHeight)
        setWindowWidth(window.innerWidth)
    }

    useEffect(() => {
        const debouncedHandleResize = debounce(updateDimensions, 1000)

        updateDimensions();
        window.addEventListener('resize', debouncedHandleResize)
        return () => {
            window.removeEventListener('resize', debouncedHandleResize)
        }
    }, [])

    useEffect(updateDimensions, [showInfo]) //TODO: Remove when modal component is implemented

    const goBack = () => {
        navigate("/devices")
    }

    const goToDeviceSettings = () => {
        if (device) {
            const deviceSettingsUrl = `/device/${device.id}/settings`
            navigate(deviceSettingsUrl)
        }
    }

    const toggleShowInfo = () => {
        setShowInfo(!showInfo)
    }
    const toggleShowExport = () => {
        setShowExport(!showExport)
    }

    const getDashboardId = (deviceTypeId: string) => {
        // Gateway Device (Mikrotik)
        if(deviceTypeId === "1704452b-80da-46f6-8816-abf60f7c9c8f"){
            return "SCvuFW96d"
        }
        // Vibration Device (RuuviTag)
        if(deviceTypeId === "7f83df8e-df7f-4e86-a24a-0f85b04fea69"){
            return "xfs3p-v3s"
        }
        // Default to soundlevel dashboard for other devicetypes
        return "gL1u05SMk"
    }

    const buttonDivStyling = "tw-flex tw-flex-nowrap tw-shrink-0 tw-m-2 tw-items-center"

    return (
        <div className="tw-flex tw-h-screen tw-flex-col">
            <div className="tw-w-full" ref={bannerContainerRef}>
                <div className="tw-flex tw-flex-column tw-flex-no-wrap ">

                    <Titlebar headline={"Grafana"} subHeadline={deviceNickname} showBottomBorder={false} /> {/*TODO: Show device id in some way */}


                    <div className="tw-ml-auto tw-mr-2 md:tw-mr-6 lg:tw-mr-10 tw-flex tw-flex-column tw-flex-wrap">
                        <div className={buttonDivStyling} >
                            <Button type="button" size="small" variant="secondary" onClick={() => setShowExport(true)}>
                                <Download className="tw-mr-0 lg:tw-mr-2 tw-h-auto" />
                                <span className="tw-hidden lg:tw-inline">Export</span>
                            </Button>
                        </div>
                        <div className={buttonDivStyling}>
                            <Button type="button" size="small" variant="secondary" onClick={goToDeviceSettings}>
                                <Gear className="tw-mr-0 lg:tw-mr-2 tw-h-auto" />
                                <span className="tw-hidden lg:tw-inline">Settings</span>
                            </Button>
                        </div>
                        <div className={buttonDivStyling}>
                            <Button type="button" size="small" variant="secondary" onClick={toggleShowInfo}>
                                <QuestionCircle className="tw-mr-0 lg:tw-mr-2 tw-h-auto" />
                                <span className="tw-hidden lg:tw-inline">Terminology</span>
                            </Button>
                        </div>
                        <div className={buttonDivStyling}>
                            <Button type="button" size="small" onClick={goBack}>
                                <XCircle className="tw-mr-0 lg:tw-mr-2 tw-h-auto" />
                                <span className="tw-hidden lg:tw-inline">Exit</span>
                            </Button>
                        </div>
                    </div>
                </div>
                {showInfo && <GrafanaTerminology onClose={toggleShowInfo} />} {/*TODO: Change from inline component to a pop-up modal (needs to be created as a component first) */}

            </div>
            <div
                className="tw-w-full tw-h-full tw-flex-1"

            >
                {device && <ExportCSV
                    onlyModalMode={true}
                    parentState={[showExport, setShowExport]}
                    variant="primary"
                    deviceId={device.id}
                    state={state}
                />}
                {device && <Grafana
                    dashboardId={getDashboardId(device.type_id ? device.type_id : "")}
                    templateVariables={{device: device.id}}
                />}
            </div>
        </div >
    )
}