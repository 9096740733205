import React, { useState, useEffect } from "react"
import { getAccount } from "../../api/api"
import SectionWrapper from "../../components/SectionWrapper"
import Titlebar from "../../components/Titlebar"
import { capitalizeFirstLetter } from "../../functions/text"

type AccountType = {
  admin: boolean
  api_key: string
  email: string
  id: string
  name: string
}

export default function Account() {
  // Fetch account using React hooks
  const [account, setAccount] = useState<AccountType>({
    admin: false,
    api_key: "",
    email: "",
    id: "",
    name: "",
  })

  useEffect(() => {
    const asyncAccount = async () => {
      setAccount(await getAccount())
    }

    asyncAccount().catch(alert)
  }, [])

  return (
    <div>
      <Titlebar headline="Account Information" />
      <SectionWrapper styles="tw-m-10 tw-p-6 tw-border">
        {account &&
          Object.entries(account).map(([key, value]) => {
            return (
              <div key={key} className="tw-grid tw-grid-cols-3 tw-gap-4">
                <div className="tw-font-bold">
                  {capitalizeFirstLetter(key)}:
                </div>
                <div className="tw-col-span-2 tw-break-words">{value.toString()}</div>
                <div></div>
              </div>
            )
          })}
      </SectionWrapper>
    </div>
  )
}
