import {ButtonGroup, ToggleButton} from "react-bootstrap";
import React from "react";

//Advanced Button Group for toogeling between advanced and basic forms
const RegisterDeviceAdvancedButtonGroup = ({formButtonOptions, advanced, setAdvanced, ...props}) => {
  return (
    <ButtonGroup toggle>
      {formButtonOptions.map((radio, idx) => (
        <ToggleButton
          key={idx}
          type="radio"
          variant="secondary"
          name="radio"
          value={radio.value}
          checked={advanced === radio.option}
          onChange={(e) => setAdvanced(radio.option)}
          >
          {radio.name}
        </ToggleButton>
        ))}
    </ButtonGroup>
  )
}

export default RegisterDeviceAdvancedButtonGroup;