import Datetime from "react-datetime";

let credentials: RequestCredentials = "include"

export var options: RequestInit = {
    credentials: credentials, // pass cookies
}

function setBase(){
  if(process.env.REACT_APP_API_KEY === undefined || process.env.REACT_APP_API_URL === undefined){
    return "http://localhost:5000"
  }
  else {
    options["headers"] = {"Authorization": "Bearer " + process.env.REACT_APP_API_KEY}
    options["credentials"] = "omit"
    return process.env.REACT_APP_API_URL
  }
}

export const base =
    process.env.NODE_ENV !== "production"
        ? setBase()
        : window.location.origin


export const getModelTemplates = async (device_ids: string[], model_ids: string[], model_names: string[],
                                        start_time?: Date, end_time?: Date, active_at?: Date) => {
  let url = `${base}/v1/ml/templates`
  let params = new URLSearchParams(
    {}
  )
  if(start_time){
    params.append('start_time', (start_time.getTime() / 1000).toString())
  }
  if(end_time){
    params.append('end_time', (end_time.getTime() / 1000).toString())
  }
  if(active_at){
    params.append('active_at', (active_at.getTime() / 1000).toString())
  }
  device_ids.forEach(id => {
    params.append('device', id)
  })
  model_ids.forEach(id => {
    params.append('id', id)
  })
  model_names.forEach(name => {
    params.append('name', name)
  })
  url = url + "?" + params
  let requestInit: RequestInit = {...options, method: "GET"}
  let response = await fetch(url, requestInit)
  let data = await response.json()
  return data.data

}