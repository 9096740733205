import { Form } from "react-bootstrap"
import Button from "../../../components/Button/Button"
import React from "react"

//Form for registering device (without selection of DeviceType, which is implemented using Select)
const RegisterDeviceForm = ({
  validated,
  advanced,
  loading,
  handleChange,
  handleSubmit,
  ...props
}) => {
  function isEmpty(alert) {
    return Object.keys(alert).length === 0 && alert.constructor === Object
  }

  return (
    <Form noValidate validated={validated} id="device-form" disabled>
      <Form.Group controlId="registerDeviceForm.nickname">
        <Form.Label>Device nickname:</Form.Label>
        <Form.Control
          type="text"
          placeholder="nickname"
          name="nickname"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="registerDeviceForm.description">
        <Form.Label>Device description:</Form.Label>
        <Form.Control
          as="textarea"
          placeholder="description"
          name="description"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group controlId="registerDeviceForm.serial">
        <Form.Label>Serial Number (required):</Form.Label>
        <Form.Control
          required
          type="text"
          placeholder="123456"
          name="serial"
          onChange={handleChange}
        />
        <Form.Text className="text-muted">
          Your product serial number can normally be found on a label on your
          device. Contact us if you have trouble locating your serial number.
        </Form.Text>
        <Form.Control.Feedback type="invalid">
          A serial number is required.
        </Form.Control.Feedback>
      </Form.Group>
      {advanced ? (
        <fieldset>
          <Form.Group controlId="registerDeviceForm.device_id">
            <Form.Label>Device ID:</Form.Label>
            <Form.Control
              type="text"
              placeholder="device_id"
              name="device_id"
              onChange={handleChange}
            />
            <Form.Text className="text-muted">
              Leave this field blank if you don't know your device id.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="registerDeviceForm.api_key">
            <Form.Label>Api Key:</Form.Label>
            <Form.Control
              type="text"
              placeholder="api-key"
              name="api_key"
              onChange={handleChange}
            />
            <Form.Text className="text-muted">
              Leave this field blank if you don't have a specific api key, and
              one will be generated for you.
            </Form.Text>
          </Form.Group>
          <Form.Group controlId="registerDeviceForm.revision">
            <Form.Label>Hardware Revision:</Form.Label>
            <Form.Control
              type="text"
              placeholder="revision number"
              name="revision"
              onChange={handleChange}
            />
            <Form.Text className="text-muted">
              Leave this field blank if you don't know which hardware revision
              your device is.
            </Form.Text>
          </Form.Group>
        </fieldset>
      ) : null}
      <Button
        type="submit"
        size="medium"
        variant="primary"
        disabled={loading || (!isEmpty(alert) && alert.variant === "success")}
        onClick={handleSubmit}
      >
        Submit
      </Button>
    </Form>
  )
}

export default RegisterDeviceForm
