import React from "react";
import Titlebar from "../../components/Titlebar";
import Header from "../../components/Header";
import { Link } from "react-router-dom";

export default function NotFound() {

    return (
        <div>
            <Titlebar headline="404 - Page not found" />
            <Header headline="The page you are looking for doesn't exist or has been moved." >

            </Header>
            <Link
                className="tw-px-10 tw-font-bold tw-block tw-mb-6 tw-cursor-pointer "
                to="/"
            >
                Navigate back to "Devices overview"
            </Link>
        </div>
    )
}