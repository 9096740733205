import React, { FormEvent, useEffect, useState } from "react"
import Button from "../../../../components/Button/Button"
import Checkbox from "../../../../components/Checkbox"
import Input from "../../../../components/Input"

type LoginFormProps = {
  handleLogin: Function
  authFailed?: boolean
  setAuthFailed?: Function
  wrongCredentials?: boolean,
  setWrongCredentials?: Function
  errorMessage?: string
}

export default function LoginForm({ handleLogin, authFailed, setAuthFailed = () => { return }, wrongCredentials, setWrongCredentials = () => { }, errorMessage }: LoginFormProps) {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [remember, setRemember] = useState<boolean>()

  const onChange = (e: FormEvent<HTMLInputElement>, field: string): void => {
    setAuthFailed(false);
    setWrongCredentials(false);

    if (field === "email") {
      setEmail((e.target as HTMLInputElement).value)
    } else if (field === "password") {
      setPassword((e.target as HTMLInputElement).value)
    } else if (field === "remember") {
      setRemember((e.target as HTMLInputElement).checked)
    }
  }

  const onFocus = (e: FormEvent<HTMLInputElement>): void => {
    setAuthFailed(false);
    setWrongCredentials(false);
  }

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault()
        document.getElementById("loginButton")!.click()
      }
    }
    document.addEventListener("keydown", listener)
    return () => {
      document.removeEventListener("keydown", listener)
    }
  }, [])

  return (
    <div className=" tw-w-fit tw-block">
      <h1 className="tw-text-48 tw-leading-48 tw-font-medium tw-pt-10">
        log in
      </h1>
      <form>
        <div className="">
          <Input
            styles={`tw-my-4 ${wrongCredentials && "tw-border-2 tw-border-status-danger"}`}
            type="email"
            value={email}
            onChange={onChange}
            onFocus={onFocus}
            placeholder="Enter your email address"
          ></Input>
          <Input
            styles={`tw-mb-4 ${wrongCredentials && "tw-border-2 tw-border-status-danger"}`}
            type="password"
            value={password}
            onChange={onChange}
            onFocus={onFocus}
            placeholder="Enter your password"
          ></Input>
        </div>
        {authFailed && errorMessage &&
          <div className="tw-w-full tw-content-center">
            <div className=" tw-bg-status-danger tw-text-primary-main-black tw-my-2 tw-px-2 tw-py-1 tw-rounded tw-flex">
              <img
                src="icon-information-custom-black.svg"
                alt="information icon"
                className="tw-mr-2"
              />
              <span>
                {errorMessage}
              </span>
            </div>
          </div>

        }
        <Checkbox
          text="Remember me"
          onChange={onChange}
          styles=""
        ></Checkbox>
        <Button
          styles="tw-mb-4"
          id="loginButton"
          type="button"
          size="large"
          variant="primary"
          onClick={() => handleLogin(email, password, remember)}
        >
          Log in
        </Button>
      </form>
    </div>
  )
}
