import React from "react";
import {BuildingOffice2Icon} from "@heroicons/react/20/solid";
import TrafficLight from "../TrafficLight";


type IconStatusPill = {
    className: string,
    selected: boolean,
    setSelected: Function,
    name: string,
    id: string,
    icon: React.ReactNode,
    status: string, /* Any of the following UNKNOWN, OK, WARNING, CRITICAL*/
}

export default function IconStatusPill({
                                           className,
                                           selected,
                                           setSelected,
                                           name,
                                           id,
                                           icon,
                                           status,
                                       }: IconStatusPill) {


    return (
        <div className={className}>
            <div className="tw-flex">
                <div onClick={() => setSelected(id)}
                    className={"tw-rounded-full tw-font-light tw-relative tw-flex-auto tw-bg-white tw-p-2 tw-cursor-default " +
                    "hover:tw-bg-gray-200 hover:tw-ring hover:tw-ring-gray-100 " +
                    (selected ? "tw-bg-gray-300 tw-ring-gray-100 tw-shadow-slate-200" : "")}>
                    <div className=" tw-flex tw-gap-2 tw-w-full">
                        <div className="tw-flex-none tw-items-center tw-justify-center">
                            {icon}
                        </div>
                        <div
                            className="tw-flex tw-items-center tw-justify-center tw-truncate">{name}</div>
                        <div className="tw-flex tw-items-center tw-justify-center">
                            <TrafficLight status={status}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}