import React, { useState, useEffect } from "react"

type InformationTooltipProps = {
  disableTimeout?: boolean,
  timeout?: number,
  children?: React.ReactNode
  stopPropagation?: boolean
}

export default function InformationTooltip({
  timeout = 5,
  disableTimeout = false,
  children,
  stopPropagation,
}: InformationTooltipProps) {
  const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)

  const handleSetOpen = (e: React.MouseEvent<HTMLInputElement>) => {
    if (stopPropagation) {
      e.stopPropagation();
    }
    setTooltipOpen(!tooltipOpen)
  }

  /**
   * If timeout is not disabeled, a timer will start when tooltip is opened. Tooltip is closed when timer expires. 
   */
  useEffect(() => {
    if (tooltipOpen && !disableTimeout) {
      let timer = setTimeout(() => {
        setTooltipOpen(false);
      }, timeout * 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [tooltipOpen]);


  return (
    <div className="tw-mt-0.5 tw-shrink-0 tw-flex" onClick={handleSetOpen}>
      <span
        className={`${tooltipOpen ? "" : "tw-hidden"
          } tw-max-w-[200px] lg:tw-max-w-[320px] tw-ml-6 tw--mt-12 tw-text-12 tw-bg-primary-off-white tw-p-2 tw-border tw-border-secondary-darker-off-white tw-rounded-normal tw-text-secondary-darker-off-white tw-font-normal tw-absolute tw-z-[100000000]`}
      >
        {children}
      </span>

      <img
        src="icon-information.svg"
        alt="information icon"
        className="tw-cursor-pointer"
      ></img>
    </div>
  )
}
