import React, { useEffect } from "react"

type InputProps = {
  placeholder?: string
  type: "email" | "password" | "text"
  value: any
  onChange: Function
  onFocus?: Function
  styles?: string
}

export default function Input({
  placeholder,
  type,
  value,
  onChange,
  onFocus = () => { return },
  styles,
}: InputProps) {
  useEffect(() => {
    const input: NodeListOf<HTMLInputElement> = document.querySelectorAll(
      "input"
    )
    if (input) {
      setInputLength(input)
    }
  })

  const setInputLength = (input: NodeListOf<HTMLInputElement>) => {
    /* for(let i = 0; i < input.length; i++){
      input[i].setAttribute('size',input?[i].getAttribute('placeholder').length);
    } */
  }
  return (
    <input
      className={`tw-rounded-full tw-block tw-w-[20rem] tw-py-3 tw-px-10 tw-bg-transparent tw-text-secondary-darker-off-white tw-placeholder-typography-muted tw-border-2 tw-border-secondary-darker-off-white focus:tw-text-typography-dark focus:tw-border-primary-main-black focus:tw-ring-primary-main-black focus:tw-outline-0 ${styles}`}
      placeholder={placeholder}
      type={type}
      value={value}
      onChange={e => onChange(e, type)}
      onFocus={e => onFocus(e, type)}
    ></input>
  )
}
