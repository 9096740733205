import {
    getActiveAnomalyClassifications,
    getAlarmsByDeviceId, getAnomalyDetectionResults,
    getBuilding,
    getOrganization,
    getRoom,
    postAlarm
} from "../../../api/api";
import {
    Alarm as AlarmModel,
    AnomalyDetectionResult,
    Building,
    Device,
    DeviceStatus,
    Organization,
    Room
} from "../../../types/dataTypes";
import {ChangeEvent, useEffect, useState} from "react";
import Button from "../../../components/Button";
import {Data, PlotMouseEvent} from "plotly.js";
import TrafficLight from "../../../components/TrafficLight";
import SectionWrapper from "../../../components/SectionWrapper";
import RangeAwarePlot from "../../../components/RangeAwarePlot"
import {PlotParams} from "react-plotly.js";
import SimpleDateSelect from "../../../components/AnomalyGraph/Components";
import {SimpleGraphDates, simpleGraphTimeConverter} from "../../../components/AnomalyGraph/Components/SimpleDateSelect";

const DAYS_OF_ANOMALY_DATA = 30;

export default function Alarm({
                                  device, status, anomalyProbabillityVisible,
                              }: { device: Device, status: DeviceStatus, anomalyProbabillityVisible: boolean }) {
    type AlarmForm = {
        timestamp?: number,
        description?: string,
        alarm_type: 'A' | 'B',
        device_id: string
    }
    const [anomalyDetectionResults, setAnomalyDetectionResults] = useState<AnomalyDetectionResult[]>([])
    const [inAlarmCreationMode, setInAlarmCreationMode] = useState(false)
    const [features, setFeatures] = useState<(string | undefined)[]>([]);
    const [existingAlarms, setExistingAlarms] = useState<AlarmModel[]>([]);
    const [alarmFormData, setAlarmFormData] = useState<AlarmForm>({device_id: device.id, alarm_type: 'A'})
    const [room, setRoom] = useState<Room>();
    const [building, setBuilding] = useState<Building>();
    const [organization, setOrganization] = useState<Organization>();
    const [selectedTime, setSelectedTime] = useState<SimpleGraphDates>('Last 30 days');
    const [inConfirm, setInConfirm] = useState<boolean>(false);

    useEffect(() => {
        setFeatures(Array.from(new Set(anomalyDetectionResults.map(adr => adr.feature))));
    }, [anomalyDetectionResults])

    const handleGraphClick = (data: Readonly<PlotMouseEvent>) => {
        if (inAlarmCreationMode) {
            if (typeof data.points[0].x === 'string') {
                setAlarmFormData({...alarmFormData, timestamp: new Date(data.points[0].x).getTime() / 1000})
            }
        }
    }

    const onAlarmFormChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setAlarmFormData({...alarmFormData, [event.target.name]: event.target.value});
    };

    useEffect(() => {
        const fetchAndSetAnomalyDetectionResults = async () => {
            const endTime = new Date();
            const startTime = new Date(endTime.getTime() - simpleGraphTimeConverter(selectedTime) * 24 * 60 * 60 * 1000)
            const data: AnomalyDetectionResult[] = await getAnomalyDetectionResults(device.id, startTime, endTime, [], "end_time", 10 ** 5)
            setAnomalyDetectionResults(data);
        }
        fetchAndSetAnomalyDetectionResults()
    }, [selectedTime])


    useEffect(() => {
        const fetchAndSetExistingAlarms = async () => {
            const data: AlarmModel[] = await getAlarmsByDeviceId(device.id);
            setExistingAlarms(data);
        }
        const fetchDeviceLocationInformation = async () => {
            if (status.device_deployment) {
                const room: Room = await getRoom(status.device_deployment.room_id);
                const building: Building = await getBuilding(room.building_id);
                const organization = await getOrganization(building.organization_id);
                setRoom(room);
                setBuilding(building)
                setOrganization(organization)
            }
        }
        fetchDeviceLocationInformation()
        fetchAndSetExistingAlarms()
    }, [])

    const formatDate = (date: Date) => {
        const pad = (a: number) => {
            if (a >= 10) {
                return `${a}`
            } else {
                return `0${a}`
            }
        }
        return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
    }


    const plotifyData = (feature: string): PlotParams => {

        const plotData: Data[] = []

        const data = anomalyDetectionResults.filter(adr => adr.feature === feature).sort((a, b) => {
            return a.end_time - b.end_time
        });
        const x = data.map(adr => formatDate((new Date(adr.end_time * 1000)))
        )
        const y = data.map(adr => adr.value ?? null)

        let yScore = data.map(adr => adr.probability ?? null)
        yScore = yScore.filter(v => typeof v === "number") as number[];

        const normalLower = data.map(adr => adr.normal_lower ?? null)
        const normalUpper = data.map(adr => adr.normal_upper ?? null)
        const normalLowerNumbers = normalLower.filter(v => typeof v === "number") as number[];
        const normalUpperNumbers = normalUpper.filter(v => typeof v === "number") as number[];
        const yNumbers = y.filter(v => typeof v === "number") as number[];
        const yLowerRange = Math.min(Math.min(...yNumbers), Math.min(...(normalLowerNumbers.length > 0 ? normalLowerNumbers : yNumbers)))
        const yUpperRange = Math.max(Math.max(...yNumbers), Math.max(...(normalUpperNumbers.length > 0 ? normalUpperNumbers : yNumbers)))

        // plot continuous subarrays
        let continuousX: string[] = []
        let continuousLower: number[] = []
        let continuousUpper: number[] = []
        let showlegend = true;
        normalLower.forEach((lowerValue, index) => {
            if (lowerValue && normalUpper[index]) {
                continuousLower.push(lowerValue)
                continuousUpper.push(normalUpper[index] as number)
                continuousX.push(x[index])
            } else {
                if (continuousLower.length > 0) {
                    plotData.push({
                            x: continuousX,
                            y: continuousLower,
                            name: "Normal lower",
                            fill: 'tozeroy',
                            fillcolor: 'transparent',
                            marker: {
                                color: 'green'
                            },
                            legendgroup: 'normal bands',
                            showlegend
                        },
                        {
                            x: continuousX,
                            y: continuousUpper,
                            fill: "tonexty",
                            fillcolor: 'lightgreen',
                            marker: {
                                color: 'green'
                            },
                            name: "Normal upper",
                            legendgroup: 'normal bands',
                            showlegend
                        })
                    continuousX = [];
                    continuousUpper = []
                    continuousLower = []
                    showlegend = false;
                }
            }
        })


        if (continuousUpper.length > 0) {
            plotData.push({
                    x: continuousX,
                    y: continuousLower,
                    name: "Normal lower",
                    fill: 'tozeroy',
                    fillcolor: 'transparent',
                    marker: {
                        color: 'green'
                    },
                    legendgroup: 'normal bands',
                    showlegend
                },
                {
                    x: continuousX,
                    y: continuousUpper,
                    fill: "tonexty",
                    fillcolor: 'lightgreen',
                    marker: {
                        color: 'green'
                    },
                    name: "Normal upper",
                    legendgroup: 'normal bands',
                    showlegend
                })
        }

        plotData.push({
            x,
            y,
            type: 'scatter',
            mode: 'lines',
            marker: {color: 'black'},
            name: feature,
        })

        plotData.push({
            x,
            y: yScore,
            type: 'scatter',
            mode: 'lines',
            yaxis: 'y2',
            fill: "tonexty",
            fillcolor: 'rgba(255, 0, 0, 0.1)',
            marker: {color: 'rgba(255, 0, 0, 0.2)'},
            name: "Anomaly Score",
            visible: anomalyProbabillityVisible ? true : "legendonly"
        })

        const formatAlarmText = (alarm: AlarmModel): string => {
            return `Alarm type: ${alarm.alarm_type}<br>${alarm.description}`
        }
        const createPreviousAlarmXYAndText = (alarm: AlarmModel, numberOfPoints: number, yLowerRange: number, yUpperRange: number) => {
            // hack to make alarm lines "hoverable" with text
            const x: string[] = []
            const y: number[] = []
            let text: string[] = []
            for (let i = 0; i < numberOfPoints; i++) {
                x.push(formatDate(new Date(alarm.timestamp * 1000)));
                y.push(yLowerRange + (yUpperRange - yLowerRange) / numberOfPoints * i)
                text.push(formatAlarmText(alarm))
            }
            return {
                x, y, text
            }
        }

        const yLowerRangeWithMargins = yLowerRange - (yUpperRange - yLowerRange) * 0.25;
        const yUpperRangeWithMargins = yUpperRange + (yUpperRange - yLowerRange) * 0.25;
        const existingAlarmData: Data[] = existingAlarms.map((pa, index) => {
                return {
                    ...createPreviousAlarmXYAndText(pa, 100, yLowerRangeWithMargins, yUpperRangeWithMargins),
                    legendgroup: 'alarms',
                    mode: 'lines+markers',
                    marker: {color: 'rgba(255, 255, 255, 0)', size: 16},
                    showlegend: index === 0,
                    name: 'Previous alarms',
                    line: {
                        dash: 'dash',
                        width: 4,
                        color: 'red'
                    },
                }
            }
        )
        plotData.push(...existingAlarmData)

        let alarmUnderCreation: AlarmModel | undefined

        if (inAlarmCreationMode && alarmFormData.timestamp) {
            const alarmUnderCreation = {
                id: "not-important",
                creator_id: "not-important",
                created_at: -1,
                alarm_type: alarmFormData.alarm_type,
                device_id: device.id,
                timestamp: alarmFormData.timestamp,
                resolved: false
            }
            plotData.push({
                ...createPreviousAlarmXYAndText(alarmUnderCreation, 100, yLowerRangeWithMargins, yUpperRangeWithMargins),
                legendgroup: 'alarms',
                mode: 'lines+markers',
                marker: {color: 'rgba(255, 255, 255, 0)', size: 16},
                showlegend: false,
                name: 'Draft alarm',
                line: {
                    dash: 'dash',
                    width: 4,
                    color: 'black'
                }
            })
        }

        const to = new Date();
        const from = new Date(to.getTime() - simpleGraphTimeConverter(selectedTime) * 24 * 60 * 60 * 1000)

        return {
            data: plotData, layout: {
                title: `Feature: ${feature}`,
                hovermode: inAlarmCreationMode ? 'x unified' : 'closest',
                autosize: true,
                xaxis: {
                    type: 'date',
                    range: [formatDate(from), formatDate(to)]
                },
                yaxis: {
                    // margins!
                    range: [yLowerRangeWithMargins, yUpperRangeWithMargins]
                },
                yaxis2: {
                    title: 'probability',
                    overlaying: 'y',
                    side: 'right'
                }
            }
        }
    }

    const plots = features.filter(f => f !== undefined).map(feature =>
        <RangeAwarePlot
            {...plotifyData(feature as string)}
            onClick={handleGraphClick}
            key={`${device.id}-${feature}`}
            className={"tw-w-full"}
        />);

    return (
        <SectionWrapper styles={"tw-m-10 tw-flex tw-flex-col tw-items-center tw-p-4"} key={device.id}>
            <div className={"tw-text-2xl tw-flex tw-flex-row tw-space-x-8 tw-items-center"}>
                    <TrafficLight status={status.status}/>
                <p>Device: {device.serial ?? 'UNKNOWN'}</p>
                <p>Organization: {organization ? organization.name : "Unknown"}</p>
                <p>Building: {building ? building.name : "Unknown"}</p>
                <p>Room: {room ? room.name ?? room.nice_name : "Unknown"}</p>
                <SimpleDateSelect selectedTime={selectedTime}
                                  setSelectedTime={(selectedTime) => setSelectedTime(selectedTime)}/>
            </div>
            {plots}
            {
                inAlarmCreationMode &&
                <form
                    onSubmit={async (e) => {
                        e.preventDefault();
                        if (alarmFormData.description && alarmFormData.timestamp) {
                            setInAlarmCreationMode(false);
                            const result: AlarmModel = await postAlarm(alarmFormData);
                            setExistingAlarms([...existingAlarms, result])
                            setAlarmFormData({...alarmFormData, timestamp: undefined, description: undefined});
                        }
                    }}
                    className={"xl:tw-w-96 tw-justify-items-center tw-text-center tw-content-center tw-grid tw-grid-cols-1 tw-content-center tw-bg-primary-off-white tw-text-secondary-darker-off-white tw-border-secondary-darker-off-white tw-p-2 tw-space-y-2 tw-border-2 tw-rounded-xl"}>
                    <label className={"tw-flex tw-flex-col"}>
                        <span>Alarm type</span>
                        <fieldset className='tw-flex tw-space-x-1'>
                            <span>A</span>
                            <input type={'radio'} defaultChecked={true} name={'alarm_type'} value={'A'}
                                   onChange={onAlarmFormChange}/>
                            <span>B</span>
                            <input type={'radio'} name={'alarm_type'} value={'B'} onChange={onAlarmFormChange}/>
                        </fieldset>
                    </label>
                    <label className={"tw-flex tw-flex-col tw-w-48"}>
                        <span>Alarm timestamp:</span>
                        <input className={"tw-rounded-md"} type="string" name="timestamp"
                               value={alarmFormData.timestamp ? formatDate(new Date(alarmFormData.timestamp * 1000)) : ""}
                               onChange={onAlarmFormChange}/>
                    </label>
                    <label className={"tw-flex tw-flex-col"}>
                        Alarm description
                        <textarea className={"tw-rounded-md tw-w-72 tw-h-36"} name={"description"}
                                  onChange={onAlarmFormChange}/>
                    </label>
                    { inConfirm && <p className={"tw-text-red-500 tw-border tw-border-gray-300 tw-rounded"}> This will trigger an alarm for the customer, are you sure? </p>}
                    <input className="hover:tw-text-gray-500" type={"button"} value={"Cancel"} onClick={() => {
                        setInAlarmCreationMode(false);
                        setInConfirm(false)
                        setAlarmFormData({...alarmFormData, timestamp: undefined, description: undefined});
                    }
                    }/>
                    {!inConfirm && <input type={"button"} value={"Submit"} onClick={() => {
                        setInConfirm(true);
                    }
                    }/>}
                    { inConfirm && <input className="tw-text-green-400 hover:tw-text-green-500" type="submit" value="Yes - Send Alarm"/>}
                </form>
            }
            {
                !inAlarmCreationMode &&
                <Button type="button" size="large" variant="secondary"
                        styles={"tw-max-w-xs"} onClick={() => {
                    setInAlarmCreationMode(true);
                }}>
                    <span>Create Alarm</span>
                </Button>
            }
        </SectionWrapper>
    )
}