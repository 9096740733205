import React, { useState } from "react"

type DropdownWrapperProps = {
  title: string
  titleIcon?: React.ReactNode
  children?: React.ReactNode
  defaultOpen: boolean
}

export default function DropdownWrapper({
  title,
  titleIcon,
  children,
  defaultOpen,
}: DropdownWrapperProps) {
  const [open, setOpen] = useState<boolean>(defaultOpen)

  return (
    <main className="tw-relative tw-overflow-x-auto tw-rounded-normal tw-bg-typography-light tw-py-2 tw-mx-0 tw-ml-10 tw-mb-5 tw-mx-10 tw-border tw-border-stroke-off-white">
      <section>
        <div className="tw-tabs">
          <div className="tw-overflow-hidden">
            <div className="tw-relative">
              <header
                onClick={() => setOpen(!open)}
                className={`tw-flex tw-justify-between tw-items-center tw-p-5 tw-pl-8 tw-pr-8 tw-cursor-pointer tw-select-none ${
                  open ? "after:tw-content-none after:tw-rotate-90" : ""
                }`}
              >
                <span className="tw-font-bold tw-text-24 tw-leading-24 tw-justify-left">
                  <div className={"tw-flex"}>
                    {titleIcon && <span className={"tw-flex-row tw-mr-3"}>{titleIcon}</span> }
                    <span className={"tw-flex-row"}>{title}</span>
                  </div>
                </span>
                <div>
                  <img
                    src="icon-chevron.svg"
                    alt="chevron icon"
                    className={`${open ? "tw-rotate-180" : ""}`}
                  />
                </div>
              </header>
              <div
                className={`${
                  open
                    ? ""
                    : "tw-max-h-0 tw-transition-all tw-duration-500"
                }`}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}
