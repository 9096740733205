import {Alert, Button, Col, Row} from "react-bootstrap";
import React from "react";

// Alert component for registered device. Shows an alert with two buttons and a success or failure message
const RegisterDeviceAlert = ({alert, navigateSuccess, clearForm, ...props}) => {

  return (
    <Alert variant={alert.variant}>
      <Row>
        <Col md={8}>
          {alert.content}
        </Col>
        {alert.variant === 'success' ?
          <><Col md={2}>
            <Button variant="primary" onClick={navigateSuccess}>Back to devices</Button>
          </Col>
          <Col md={2}>
            <Button variant="secondary" onClick={clearForm}>Add another</Button>
          </Col></>
          : ""
        }
      </Row>
    </Alert>
  )
}

export default RegisterDeviceAlert;