import React from "react"
import moment from "moment"

export const renderDecibel = (db, label = "") => {
  return (
    <span>
      {label}
      {db.toFixed(0)} <small>dB(A)</small>
    </span>
  )
}

export const renderTime = date => {
  return moment(date).from(moment.utc(moment.now()))
}

export const unixTimeStampToDate = timestamp => {
  return moment.unix(timestamp).format("YYYY-MM-DD HH:mm:ss")
}

export const checkIfTimeIsInTimeInterval = (timeToCheck, timeInterval) => {
  return timeInterval[0].anomalies.find(interval =>
    moment(timeToCheck).isBetween(interval.x0, interval.x1, undefined, "[]")
  )
}
