import React from "react"

type CheckboxProps = {
  text: string
  onChange: Function
  styles?: string
}

export default function Checkbox({ text, onChange, styles }: CheckboxProps) {
  return (
    <div
      className={`tw-font-bold tw-block tw-mb-6 tw-cursor-pointer tw-w-48 ${styles}`}
    >
      <input
        className="mr-2"
        type="checkbox"
        onChange={e => onChange(e, "remember")}
      ></input>
      <span>{text}</span>
    </div>
  )
}
