import moment from "moment"

export const currentMonthRange = () => {
  return [
    moment().startOf("month").toDate(),
    moment()
      .startOf("month")
      .add(1, "months").toDate(),
  ]
}
