import React, { useState, useEffect } from "react"
import { useParams } from "react-router"
import { getAccount } from "../../../../api/api"
import Grafana from "../../Grafana"
import { Container } from "react-bootstrap"

export default props => {
  /*
    Embedding of our grafana dashboard that shows multiple devices at a time.

    The url props device_id and org_id are just to set what organization or device will
    be selected by default in grafana.
    The user will still have access to all organizations he is associated with, and all devices
    those organizations have, using the dropdowns in the dashboard.

    The user ID is sent to grafana because we need it to query the correct organizations to
    populate the dropdown.
  */

  const params = useParams()
  const deviceId = params.device_id
  const orgId = params.org_id

  const [userId, setUserId] = useState()

  useEffect(() => {
    const asyncAccount = async () => {
      const user = await getAccount()
      setUserId(user["id"])
    }
    asyncAccount().catch(alert)
  }, [])

  if (!userId) {
    return null
  }

  let variables = { soundsensingUserId: userId }
  if (deviceId) {
    variables.device = deviceId
  }
  if (orgId) {
    variables.soundsensingOrgId = orgId
  }

  return (
    <Container
      fluid
      style={{ background: "#202226", height: "calc(100vh - 56px)" }}
    >
      <Grafana dashboardId={"Y5IkOi9Gz-R"} templateVariables={variables} />
    </Container>
  )
}
