import React from "react"

type HeaderProps = {
  headline: string
  headlineStyles?: string
  children?: React.ReactNode
}

export default function Header({
  headline,
  headlineStyles,
  children,
}: HeaderProps) {
  // Device name
  return (
    // If adding filter component, use justify-evenly on parent
    <div className="2xs:tw-block sm:tw-flex tw-pt-10 tw-px-10 tw-pb-4">
      <h1
        className={`2xs:tw-text-24 2xs:tw-leading-24 2xs:tw-pb-6 sm:tw-text-28 sm:tw-leading-28 tw-font-normal tw-pr-20 ${headlineStyles}`}
      >
        {headline}
      </h1>
      {children}
    </div>
  )
}
