import React from "react"

export default function LoginGraphic() {
  return (

    <div className="tw-overflow-hidden tw-w-full tw-min-h-screen">
      <img
        src="graphic-login.svg"
        className="tw-object-cover tw-h-screen"
        alt="graphic soundelement"
      ></img>
    </div>
  )
}
