import Plot, {PlotParams} from "react-plotly.js";
import {useState} from "react";
import {PlotRelayoutEvent} from "plotly.js";
import * as Plotly from "plotly.js";

export default function RangeAwarePlot(props: PlotParams) {
  // a plot that won't automatically resize whenever props change. This means that the user can zoom into the
  // plot etc. without resizing when props change.
  const [xAxisRange, setXAxisRange] = useState<any[] | undefined>(undefined);
  const [yAxisRange, setYAxisRange] = useState<any[] | undefined>(undefined);

  const handleRelayout = (event: Readonly<PlotRelayoutEvent>) => {
    const x0 = event['xaxis.range[0]']
    const x1 = event['xaxis.range[1]']
    setXAxisRange(x0 && x1 ? [x0, x1] : undefined)
    const y0 = event['yaxis.range[0]']
    const y1 = event['yaxis.range[1]']
    setYAxisRange(y0 && y1 ? [y0, y1] : undefined);
  }
  return <Plot {...props} onRelayout={handleRelayout} layout={
    {
      ...props.layout,
      xaxis: {...props.layout.xaxis, ...(xAxisRange && {range: xAxisRange})},
      yaxis: {...props.layout.yaxis, ...(yAxisRange && {range: yAxisRange})}
    }
  }/>
}
