import React from "react";

type ToastProps = {
    text: string
    type?: string
}
/**
 * The component renderes a toast in the lower right corner. 
 * 
 * @param text: Text that will be displayed inside toast.
 * @param type: Color of toast. Be be any of the colors defined in tailwind.config.js file, but prefrably "danger", "warning" and "success". 
 * @returns 
 */
export function Toast({ text, type = "unknown" }: ToastProps) {

    return (
        <div className={`tw-max-w-[320px]
         tw-ml-6 tw--mt-12 tw-p-2
          tw-text-12 tw-text-primary-main-black tw-font-normal
          tw-bg-status-${type} tw-border tw-border-secondary-darker-off-white tw-rounded 
          tw-absolute tw-bottom-6 tw-right-6
          tw-z-[100000000]`}>
            <div className="tw-flex">
                <img
                    src="icon-information-custom-black.svg"
                    alt="information icon"
                    className="tw-mr-2"
                />
                <span>
                    {text}
                </span>

            </div>

        </div>
    )
}

