import React, {useState, useEffect} from "react"
import {Row, Col, Container, Button} from "react-bootstrap"
import {useNavigate, useParams} from "react-router"
import {Building, Device, DeviceDeployment, Room} from "../../types/dataTypes";
import {getBuilding, getBuildings, getDevice, getDeviceDeployment, getRoom} from "../../api/api";
import Grafana from "../../old/components/Grafana";

type CmHeaderProps = {
  deviceDeployment: DeviceDeployment, building: Building, room: Room, device: Device
}


const CMHeader = ({
                    deviceDeployment,
                    building, room, device
                  }: CmHeaderProps) => {
  const navigate = useNavigate()
  const deviceName = device.nickname
    ? `${device.nickname} (Serial ${device.serial})`
    : `Serial ${device.serial}`
  const installTime = new Date(
    deviceDeployment.start_time * 1000
  ).toLocaleString()

  const columns: { [key: string]: string } = {
    Device: deviceName,
    Address: building.name ?? building.address,
    Room: room.nice_name ?? room.name,
    Installed: installTime,
  }
  return (
    <Row className="text-light" style={{height: "58px", paddingLeft: "15px"}}>
      {Object.keys(columns).map(c => (
        <Col key={c} className="my-auto">
          {c + ": " + columns[c]}
        </Col>
      ))}
      <Col className="my-auto"><Button variant={"primary"} onClick={() => navigate("/")}><span
        className="tw-hidden lg:tw-inline">Exit</span></Button></Col>
    </Row>
  )
}

export default () => {
  const [deviceDeployment, setDeviceDeployment] = useState<DeviceDeployment>()
  const [room, setRoom] = useState<Room>()
  const [building, setBuilding] = useState<Building>()
  const [device, setDevice] = useState<Device>()

  const params = useParams()
  const deviceLocationId = params.location_id

  useEffect(() => {
    const getData = async () => {
      const devLoc = await getDeviceDeployment(deviceLocationId)
      setDeviceDeployment(devLoc)
    }
    getData().catch(alert)
  }, [])

  useEffect(() => {
    const getRoomData = async () => {
      if(deviceDeployment && deviceDeployment.room_id) {
        const r = await getRoom(deviceDeployment.room_id)
        setRoom(r)
      }
    }
    getRoomData().catch(alert)
    const getDeviceData = async () => {
      if(deviceDeployment && deviceDeployment.room_id) {
        const d = await getDevice(deviceDeployment?.device_id)
        setDevice(d)
      }
    }
    getDeviceData().catch(alert)
  }, [deviceDeployment])

  useEffect(() => {
    const getData = async () => {
      if(room && room.building_id) {
        const b = await getBuilding(room?.building_id)
        setBuilding(b)
      }
    }
    getData().catch(alert)
  }, [room])

  //TODO: Switch to device deployment
  if (deviceDeployment && room && building && device) {
    const variables = {
      device: deviceDeployment.device_id,
      locationStartTime: deviceDeployment.start_time,
      locationEndTime: deviceDeployment.end_time ? deviceDeployment.end_time : 0,
      deviceLocation: deviceDeployment.id
    }

    return (
      <Container
        fluid
        style={{
          background: "#202226",
          height: "calc(100vh)",
          display: "flex",
          flexFlow: "column",
          paddingLeft: 0,
          paddingRight: 15,
        }}
      >
        <CMHeader deviceDeployment={deviceDeployment!} device={device!} room={room!} building={building!}/>
        <Grafana dashboardId={"3PlCoQ1nz"} templateVariables={variables}/>
      </Container>
    )
  } else {
    return null
  }
}
