import React, {useEffect, useRef, useState} from "react"
import LazyLoad from "react-lazyload"
import SectionWrapper from "../../components/SectionWrapper"
import Header from "../../components/Header"
import DeviceQuickView from "./components/DeviceQuickView"
import DropdownWrapper from "../../components/DropdownWrapper"
import Titlebar from "../../components/Titlebar"
import {
  Alarm,
  Asset,
  Building,
  Device,
  DeviceStatus,
  ModelTemplate,
  Organization, Room,
} from "../../types/dataTypes"
import Search from "../../components/Search"
import {getActiveModelTemplates, getAlarm, getAlarms, getDeviceCondition,} from "../../api/api"
import {ConditionRow, TempState} from "../../types/componentTypes"
import {debounce} from "../../functions/general"
import OrganizationSelect from "../../components/OrganizationSelect";
import AssetList from "./components/AssetList";
import IconStatusPill from "../../components/IconStatusPill";
import {BuildingOffice2Icon, CubeIcon} from "@heroicons/react/20/solid";
import {createConditionRowsForOrg} from "./ConditionOrganizationDataUtil";
import TrafficLight from "../../components/TrafficLight";
import {availableOrganizations} from "../../functions/organizationHelpers";

type ConditionMonitoringProps = {
  state: TempState
}

export default function ConditionMonitoring({
                                              state,
                                            }: ConditionMonitoringProps) {
  const [search, setSearch] = useState("")
  const [devices, setDevices] = useState<Device[]>([])
  const [deviceDataLoading, setDeviceDataLoading] = useState(true)
  const [showQuickView, setShowQuickView] = useState(false)
  const [windowHeight, setWindowHeight] = useState(0)
  const [bannerHeight, setBannerHeight] = useState(0)
  const [quickViewHeight, setQuickViewHeight] = useState(0)
  const [selectedOrg, setSelectedOrg] = useState<Organization>()
  const [cmRows, setCmRows] = useState<ConditionRow[]>([])
  const [selectedBuilding, setSelectedBuilding] = useState<Building>()
  const [selectedRoom, setSelectedRoom] = useState<Room>()
  const [currentAsset, setCurrentAsset] = useState<Asset>(
    {
      id: "",
      name: "",
      room_id: "",
      registered_by: "",
      created_at: 0,
      model: "",
      parent_id: "",
      asset_category_id: ""
    })
  const [alarms, setAlarms] = useState<Alarm[]>([])
  const pageContainerRef = useRef<any>(null);
  const bannerContainerRef = useRef<any>(null);
  const quickViewContainerRef = useRef<any>(null);

  const availableOrgs = availableOrganizations(state.organizations, state.buildings)

  /** Handle scaling of windows **/

  const updateContainerHeights = () => {
    setWindowHeight(pageContainerRef.current.clientHeight)
    setBannerHeight(bannerContainerRef.current.clientHeight)
    setQuickViewHeight(quickViewContainerRef.current.clientHeight)
  }

  useEffect(() => {
    updateContainerHeights()
  })

  useEffect(() => {
    const debouncedHandleResize = debounce(() => updateContainerHeights(), 1000)

    window.addEventListener('resize', debouncedHandleResize)

    return () => {
      window.removeEventListener('resize', debouncedHandleResize)
    }
  }, [])

  /** Handle quickview **/

  const handleShowQuickView = (isOpen: boolean, asset: Asset, device: Device, deviceStatus: DeviceStatus) => {
    if (isOpen) {
      currentAsset.isOpen = false
      setShowQuickView(true)
      setCurrentAsset(asset)
      asset.isOpen = true
    } else {
      setShowQuickView(false)
      asset.isOpen = false
    }
  }

  const closeQuickView = () => {
    setShowQuickView(false)
    currentAsset.isOpen = false
  }

  const isQuickViewOpen = () => {
    return showQuickView;
  }

  /** Handle search **/

  const handleSearch = (event: any) => {
    if (event.target.value.length > 0) {
      document.getElementById("search-icon")!.style.visibility = "hidden"
    } else {
      document.getElementById("search-icon")!.style.visibility = "visible"
    }
    setSearch(event.target.value)
  }

  const filterDataBySearch = () => {
    const searchRegex = new RegExp(search, "i") // 'i' means ignore case
    let filteredCmRows = cmRows.filter(row => {
      return (
        searchRegex.test(row.room.name) ||
        searchRegex.test(row.room.nice_name ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH") ||
        searchRegex.test(row.building.address) ||
        searchRegex.test(row.building.name ?? "THIS SHOULD NEVER BE CONTAINED IN THIS SEARCH") ||
        searchRegex.test(row.asset.name) ||
        searchRegex.test(row.asset.model) ||
        searchRegex.test(row.asset.id) ||
        row.devices.some(d => {
            return (
              searchRegex.test(d.id) ||
              searchRegex.test(d.serial)
            )
          }
        )
      )
    })
    let buildings = filteredCmRows.map(r => r.building).filter((building, i, arr) => {
      return arr.indexOf(arr.find(b => b.id === building.id)!) === i;
    })
    if ((buildings.length > 0) && !buildings.map(b => b.id).includes(selectedBuilding!.id)) {
      selectBuilding(buildings[0])
      let rooms = filteredCmRows.map(r => r.room).filter((room, i, arr) => {
        return arr.indexOf(arr.find(r => r.id === room.id)!) === i;
      })
      if (rooms.length > 0 && !rooms.map(r => r.id).includes(selectedRoom!.id)) {
        setSelectedRoom(rooms[0])
      }
    }
    return filteredCmRows
  }


  /** Handle data flow **/

  useEffect(() => {
    if (state?.organizations && !selectedOrg) {
      let org = availableOrgs[0]
      selectOrg(org)
    }
  }, [state, selectedOrg])

  useEffect(() => {
    /** Update devices from state to include only devices that are currently active in a model template **/
    const fetchDevices = async () => {
      setDeviceDataLoading(true)
      if ((state.deviceDeployments?.length ?? 0) > 0 && selectedOrg !== undefined) {
        try {
          setDeviceDataLoading(true)
          let data = await getActiveCMDevices(state.devices)
          // Remove devices that don't have an organization
          data = data.filter((device: Device) => device.organization?.id === selectedOrg?.id)
          setDevices(data)
        } catch (err) {
          console.log(err)
        }
        setDeviceDataLoading(false)
      }
    }
    fetchDevices()
  }, [state, selectedOrg])

  const getActiveCMDevices = async (devices: Device[]) => {
    // Get active templates
    const templates = await getActiveModelTemplates()
    let activeDeviceIds: string[] = []
    templates.forEach((t: ModelTemplate) => {
      t.devices?.forEach((d: Device) => {
        if (!activeDeviceIds.includes(d.id)) {
          activeDeviceIds.push(d.id)
        }
      })
    })

    return devices.filter(d => activeDeviceIds.includes(d.id))
  }

  useEffect(() => {
    const fetchDeviceAlarms = async () => {
      const now = new Date()
      if (selectedOrg !== undefined && devices.length > 0) {
        let alarmPromise = getAlarms(
          [],
          [selectedOrg.id]) // 7 days of data to determine status
        alarmPromise.then(alarms =>
          setAlarms(alarms)
        )
      }

    }
    fetchDeviceAlarms()
  }, [devices, selectedOrg])

  useEffect(() => {
    let orgbuilds = state.buildings?.filter(b => b.organization_id === selectedOrg?.id) ?? []
    let cmRows = createConditionRowsForOrg(orgbuilds, state.rooms, state.assets, state.deviceDeployments,
      devices, alarms) //Use updated devices here, to ensure only cm activated devices are in rows.
    setCmRows(cmRows)
    setSelectedBuilding(orgbuilds[0])
  }, [alarms])

  function selectOrg(selectedOrg: Organization) {
    setSelectedOrg(selectedOrg)
    let building = state?.buildings?.filter((building: Building) =>
      (building.organization_id === selectedOrg.id))[0]
    if (building) {
      selectBuilding(building)
    }
    closeQuickView()
  }

  function selectBuilding(pressedBuilding: Building) {
    let room = state?.rooms?.filter((room: Room) =>
      (room.building_id === pressedBuilding.id))[0]
    setSelectedRoom(room)
    setSelectedBuilding(pressedBuilding)

  }

  useEffect(() => {
    //This useEffect should not be neccisary, but it is.. Somehow selectBuildings setSelectedRoom does not result in rerendering unless debugger is attatched.
    //This useEffect counters that by making sure room belongs to building..
    if (selectedRoom && selectedBuilding && selectedRoom.building_id !== selectedBuilding.id) {
      let room = state?.rooms?.filter((room: Room) =>
        (room.building_id === selectedBuilding.id))[0]
      setSelectedRoom(room)
    }
  }, [selectedRoom, selectedBuilding])

  const filteredCmRows = filterDataBySearch()

  return (
    <div className="tw-h-full" ref={pageContainerRef}>
      <div ref={bannerContainerRef}>
        <Titlebar headline="Condition Monitoring"/>
        <span className={`tw-pt-1 tw-flex-wrap ${showQuickView ? "tw-hidden lg:tw-block" : ""}`}>
          <Header headline="Buildings overview" headlineStyles="tw-pt-2">
            <Search handleSearch={handleSearch}></Search>
            <OrganizationSelect
              availableOrganizations={availableOrgs}
              selected={selectedOrg}
              setSelected={selectOrg}
              className="tw-flex-wrap tw-mt-2 lg:tw-absolute lg:tw-right-10"/>
          </Header>
        </span>
      </div>
      <div className="tw-flex tw-flex-wrap tw-flex-row tw-px-10 tw-pb-10 tw-gap-4">
        <div className="tw-flex tw-items-center tw-justify-center tw-text-md">Building</div>
        {(state.buildings?.length ?? 0 > 0) && selectedOrg && ( //TODO: Need to use cmrow buildings here?
          state.buildings!.filter(b => filteredCmRows.map(r => r.building.id).includes(b.id)).map((building) => {

            return (
              <IconStatusPill key={building.id} className={""} selected={selectedBuilding?.id === building.id}
                              setSelected={(id: String) => {
                                selectBuilding(building);
                                closeQuickView()
                              }}

                              name={building?.name ?? building.address}
                              id={building.id} status={building.status ?? "UNKNOWN"}
                              icon={<BuildingOffice2Icon className="tw-h-5 tw-w-5 tw-text-gray-400"
                                                         aria-hidden="true"/>}/>
            )
          }))
        }
      </div>
      {selectedBuilding &&
      <div className="tw-flex tw-flex-wrap tw-flex-row tw-px-10 tw-pb-10 tw-gap-4">
        <div className="tw-flex tw-items-center tw-justify-center tw-text-md">Room</div>
        {(state.rooms?.length ?? 0 > 0) && selectedOrg && ( //TODO: Need to use cmrow buildings here?
          state.rooms!.filter(room => filteredCmRows.map(row => row.room.id).includes(room.id) && room.building_id === selectedBuilding.id).map((room) => {

            return (
              <IconStatusPill key={room.id} className={"tw-text-sm"} selected={selectedRoom?.id === room.id}
                              setSelected={(id: String) => {
                                setSelectedRoom(room);
                                closeQuickView()
                              }}

                              name={room?.nice_name ?? room.name}
                              id={room.id} status={room.status ?? "UNKNOWN"}
                              icon={<CubeIcon className="tw-h-5 tw-w-5 tw-text-gray-400"
                                              aria-hidden="true"/>}/>
            )
          }))
        }
      </div>
      }
      <div className="tw-flex tw-flex-row tw-flex-nowrap">
        <div className={`tw-flex-auto ${showQuickView ? `tw-hidden lg:tw-block lg:tw-overflow-y-scroll` : ""}`}
             style={{
               height: showQuickView ?
                 (quickViewHeight > (windowHeight - bannerHeight) ?
                   `${quickViewHeight}px`
                   :
                   `calc(${windowHeight - bannerHeight}px - 1rem)`)
                 :
                 "calc(100% - 1rem)"
             }}>
          {selectedBuilding && selectedRoom && selectedRoom.building_id == selectedBuilding!.id && filteredCmRows.length > 0 &&
          <div key={selectedRoom.id} className={""}>
            <DropdownWrapper
              key={"dropdown-" + selectedRoom.id}
              title={selectedRoom.nice_name ?? selectedRoom.name}
              titleIcon={<TrafficLight status={selectedRoom.status ?? "UNKNOWN"}/>}
              defaultOpen={true}
            >
              {((state.assets?.length ?? 0 > 0) && (state.deviceDeployments?.length ?? 0 > 0)) &&
              (<AssetList
                key={"assetlist-" + selectedRoom.id}
                room={selectedRoom}
                cmRows={filteredCmRows.filter(cmr => cmr.room.id === selectedRoom.id)}
                currentAsset={currentAsset}
                openQuickView={handleShowQuickView}
                isQuickViewOpen={isQuickViewOpen}
              />)}
            </DropdownWrapper>
          </div>
          }
          {devices.length === 0 && (
            <SectionWrapper styles="tw-border tw-ml-10 tw-p-5 tw-my-5 tw-mr-10">
              {deviceDataLoading ?
                <p className="tw-italic">
                  Loading devices...
                </p>
                :
                <p className="tw-italic">
                  Could not retrieve any devices that run Condition Monitoring. Please contact us for help.
                </p>}
            </SectionWrapper>
          )}
        </div>
        <div
          className={`tw-flex-[2_1_50%] tw-min-w-min ${showQuickView ? "tw-block" : "tw-hidden"
          }`}
        >
          <div ref={quickViewContainerRef}>
            <SectionWrapper
              styles="tw-border tw-sticky tw-top-[2.5rem] tw-self-start tw-p-5 tw-my-5 tw-mx-5 lg:tw-mt-0 lg:tw-m-10 ">
              {showQuickView && (
                <DeviceQuickView
                  key={currentAsset.id}
                  cmRow={cmRows.find(cm => cm.asset == currentAsset)!}
                  closeDeviceQuickView={closeQuickView}
                  onUpdate={updateContainerHeights}
                />
              )}
            </SectionWrapper>
          </div>

        </div>
      </div>
    </div>
  )
}
