import React from "react"

type LogoutProps = {
  routeChange: Function
}

export default function Logout({ routeChange }: LogoutProps) {
  return (
    <div className="tw-top-1/2 tw-relative 2xs:tw-translate-y-2/4 lg:-tw-translate-y-2/4">
      <h1 className="tw-text-48 tw-leading-48 tw-font-medium">logged out</h1>
      <div
        className="tw-font-bold tw-block tw-mb-6 tw-cursor-pointer tw-mt-6"
        onClick={() => routeChange("/login")}
      >
        Log back in again
      </div>
    </div>
  )
}
