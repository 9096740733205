import React from "react"

type SearchProps = {
  handleSearch: Function
}

export default function Search({ handleSearch }: SearchProps) {
  return (
    <div>
      <div className="tw-flex tw-pb-1 tw-mt-2">
        <div className="xl:tw-w-96">
          <div className="tw-input-group tw-relative tw-flex tw-flex-wrap tw-items-stretch tw-w-full">
            <input
              id="search-input"
              type="search"
              className="tw-form-control tw-rounded-full tw-font-light tw-relative tw-flex-auto tw-min-w-0 tw-block tw-w-full tw-h-[45px] tw-px-3 tw-pl-7 tw-py-1.5 tw-m-0 tw-text-typography-muted tw-placeholder-secondary-darker-off-white tw-bg-white tw-bg-clip-padding tw-border tw-border-white tw-transition tw-ease-in-out focus:tw-text-typography-muted focus:tw-bg-white focus:tw-border-typography-muted focus:tw-outline-none"
              placeholder="Search"
              aria-label="Search"
              onChange={e => handleSearch(e)}
            />
            <span className="tw-absolute tw-z-5 tw-pt-4 tw-right-5">
              <img id="search-icon" src="icon-search.svg" alt="search icon" />
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
