import React, { createRef, useState } from "react"
import { Alert, Button, Col, Container, Form, Row } from "react-bootstrap"
import { capitalizeFirstLetter } from "../../../functions/text"
import { ArrowLeft, Pencil } from "react-bootstrap-icons"
import { useNavigate, useParams } from "react-router-dom"
import { editDevice } from "../../../api/api"

export default ({ state = state, updateState, ...props }) => {
  const navigate = useNavigate()
  const params = useParams()
  console.log("Params in DeviceSettings", params)
  const deviceId = params.device_id
  console.log("deviceId in DeviceSettings", deviceId)

  const [editing, setEditing] = useState()
  const [alert, setAlert] = useState()

  const displayLocations = locations => {
    if (!locations) {
      return ""
    }

    const formatKeyValuesForDisplayLocation = (list, keys, header) => {
      let lines = header + ": \n"
      keys.forEach(key => {
        if (list[key]) {
          let value = ""
          if (key.includes("time")) {
            value = new Date(list[key] * 1000).toISOString()
          } else {
            value = list[key]
          }
          lines +=
            "    " +
            key.charAt(0).toUpperCase() +
            key.slice(1) +
            ": " +
            value +
            "\n"
        }
      })
      return lines
    }

    let lines = ""
    locations.sort((a, b) => {
      return b.end_time === null || a.end_time > b.end_time
    })
    locations.forEach(loc => {
      let topKeys = Object.keys(loc).filter(key => key !== "location")
      let locKeys = Object.keys(loc["location"])
      lines += formatKeyValuesForDisplayLocation(
        loc,
        topKeys,
        "Device Location"
      )
      lines += formatKeyValuesForDisplayLocation(
        loc.location,
        locKeys,
        "Location"
      )
      lines += "\n"
    })

    return lines
  }

  if (state && state.devices && deviceId) {
    const device =
      state.devices[state.devices.findIndex(device => device.id === deviceId)]

    const editableAttributes = ["nickname", "description"]

    const excludedAttributes = [
      "organization_id",
      "sensor_id",
      "type_id",
      "registered_by",
      "last_soundlevel",
      "last_seen",
    ]

    const formatDeviceAttributes = device => {
      let filteredDevice = Object.keys(device)
        .filter(key => !excludedAttributes.includes(key))
        .reduce((obj, key) => {
          obj[key] = device[key]
          return obj
        }, {})

      //Put nickname first, since that's what customers can change
      filteredDevice = Object.assign({ nickname: null }, filteredDevice)

      //Date
      filteredDevice["registered_at"] = new Date(
        filteredDevice["registered_at"] * 1000
      ).toString() //ms

      //DeviceType
      filteredDevice[
        "type"
      ] = `${filteredDevice["type"]["brand"]} ${filteredDevice["type"]["model"]}`

      //Organization
      filteredDevice["organization"] = filteredDevice["organization"]
        ? filteredDevice["organization"]["name"]
        : null

      filteredDevice["locations"] = displayLocations(device.locations)

      return filteredDevice
    }

    const formattedDevice = formatDeviceAttributes(device)

    const attributeRow = key => {
      const value = formattedDevice[key]
      const inputRef = createRef()

      const handleEditClick = () => {
        setEditing(key)
        inputRef.current.focus()
      }
      const handleDiscardClick = () => {
        setEditing(undefined)
        inputRef.current.value = device[key]
      }
      const handleSaveClick = async () => {
        const editAttributes = { [key]: inputRef.current.value }
        const response = await editDevice(deviceId, editAttributes)
        if (response.ok) {
          const data = await response.json()
          const user = data.data
          if (user) {
            //Success
            await updateState()
            setAlert([`Successfully updated ${key}`, "success"])
            setEditing(undefined)
          } else setAlert(["Something went wrong..", "danger"])
        } else setAlert(["Something went wrong..", "danger"])
      }

      const editButtons = () => {
        if (editing !== key) {
          return (
            <Button
              variant="primary"
              size="sm"
              data-testid={key + "-edit-button"}
              onClick={handleEditClick}
            >
              Edit <Pencil />
            </Button>
          )
        } else {
          return (
            <div style={{ display: "flex" }}>
              <Button
                size="sm"
                variant="success"
                data-testid={key + "-save-button"}
                onClick={handleSaveClick}
              >
                Save
              </Button>
              <Button
                size="sm"
                className="ml-2"
                variant="danger"
                data-testid={key + "-discard-button"}
                onClick={handleDiscardClick}
              >
                Discard
              </Button>
            </div>
          )
        }
      }

      return (
        <Form.Group as={Row} key={key} controlId={`formControl${key}`}>
          <Form.Label column sm={2} className="bg-light border">
            {capitalizeFirstLetter(key.replace("_", " "))}
          </Form.Label>
          <Col className="bg-light border">
            <Row>
              <Col>
                <Form.Control
                  plaintext={editing !== key}
                  readOnly={editing !== key}
                  onKeyPress={
                    editing === key
                      ? event => {
                          event.key === "Enter" && handleSaveClick()
                        }
                      : null
                  }
                  defaultValue={value}
                  ref={inputRef}
                  as={
                    key === "description" || key === "locations"
                      ? "textarea"
                      : "input"
                  }
                />
              </Col>
              <Col sm={3} style={{ display: "flex" }}>
                {editableAttributes.includes(key) && editButtons()}
              </Col>
            </Row>
          </Col>
        </Form.Group>
      )
    }

    return (
      <Container>
        <Button
          variant="outline-primary"
          size="lg"
          className="mt-2"
          onClick={() => navigate(-1)}
        >
          <ArrowLeft /> Back
        </Button>
        <h1 className="pt-3 pb-2">Device settings</h1>
        <Row>
          <Col>
            {Object.keys(formattedDevice).length > 0 && (
              <Form>
                {Object.keys(formattedDevice).map(key => attributeRow(key))}
              </Form>
            )}
          </Col>
        </Row>
        <Row>
          {alert && alert.length === 2 && (
            <Col>
              <Alert variant={alert[1]}>{alert[0]}</Alert>
            </Col>
          )}
        </Row>
      </Container>
    )
  } else return null
}
