import React, { useState, useEffect } from "react"
import { Button, Modal, Spinner, Container, Row, Col } from "react-bootstrap"
import { CSVLink } from "react-csv"
import moment from "moment"
import { csvFormat } from "../../functions/csv"
import { currentMonthRange } from "../../functions/time"
import { getSoundlevels } from "../../api/api"
import XLSX from "xlsx"
import DateTimePicker from "react-datetime-picker"
import ReactDOM from "react-dom"
import { Download } from "react-bootstrap-icons"

//LAFmin below this number will be replaced with empty value
const minimumLAFmin = 20

export const ExportCSV = ({ deviceId, state, parentState, onlyModalMode = false, ...props }) => {
  // Modal show handling
  const [selfShow, selfSetShow] = useState(false)
  const [show, setShow] = parentState ? [...parentState] : [selfShow, selfSetShow]

  const handleClose = () => setShow(false)
  const handleShow = () => {
    setShow(true)
  }

  const [range, setRange] = useState(currentMonthRange()) // Time range for data is set to current month rang eby default
  const [soundlevels, setSoundlevels] = useState()
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [exportFileType, setExportFileType] = useState(".csv")

  const device =
    state.devices[state.devices.findIndex(device => device.id === deviceId)]

  // Get soundlevels when the time range is changed.
  useEffect(() => {
    const getLevels = async range => {
      setIsLoadingData(true)
      const levels = await getSoundlevels(
        [deviceId],
        [],
        range[0],
        range[1]
      ).then(result => {
        //Remove LAFmin values below minimum
        result.forEach(sl => {
          if (sl.lafmin < minimumLAFmin) {
            sl.lafmin = ""
          }
        })

        setSoundlevels(result)
        setIsLoadingData(false)
      })
    }
    if (range) {
      //only get soundlevels when range is not undefined, which is after clicking export.
      getLevels(range)
    }
  }, [range])

  const exportXLSX = data => {
    /* convert Array of objects to worksheet */
    const ws = XLSX.utils.json_to_sheet(data)

    /* build new workbook */
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS")

    /* write file */
    const singleMonth =
      moment(range[0]).format("MMMM") == moment(range[1]).format("MMMM")
    const filename = `${device.serial}_${moment(range[0]).format("MMMYYYY")}${singleMonth ? "" : "-" + moment(range[1]).format("MMMYYYY")
      }.xlsx`
    XLSX.writeFile(wb, filename)
  }

  const ExportButton = () => {
    const data = csvFormat(soundlevels, range, state.devices)
    if (exportFileType == ".csv") {
      const singleMonth =
        moment(range[0]).format("MMMM") == moment(range[1]).format("MMMM")
      const filename = `${device.serial}_${moment(range[0]).format("MMMYYYY")}${singleMonth ? "" : "-" + moment(range[1]).format("MMMYYYY")
        }.csv`
      return (
        <CSVLink
          data={data}
          filename={filename}
          target="_blank"
          className={`btn btn-primary w-100 ${data.length === 0 && `disabled`}`}
          style={{ verticalAlign: "middle", display: "inline-block" }}
        >
          {(data.length === 0 && `No data available`) || `Download`}
        </CSVLink>
      )
    } else if (exportFileType == ".xlsx") {
      return (
        <Button
          onClick={() => exportXLSX(data)}
          style={{ verticalAlign: "middle", display: "inline-block" }}
          className={`btn btn-primary w-100 ${data.length === 0 && `disabled`}`}
        >
          {(data.length === 0 && `No data available`) || `Download`}
        </Button>
      )
    }
  }

  const handleRangeChange = (startTime, endTime) => {
    let newRange = [...range]
    if (startTime) {
      newRange[0] = startTime
    }
    if (endTime) {
      newRange[1] = endTime
    }
    setRange(newRange)
  }

  //This had to be done for the calendar to work properly on firefox and safari.
  const onRef = picker => {
    if (picker) {
      const pickerEl = ReactDOM.findDOMNode(picker)
      pickerEl.tabIndex = -1
    }
  }

  if (device) {
    //If on mobile, the button should just be an icon.
    const onMobile = window.innerWidth < 769
    return (
      <>
        {!onlyModalMode &&
          <Button {...props} onClick={handleShow}>
            {onMobile ? "" : "Export "}
            <Download />
          </Button>}

        <Modal show={show} onHide={handleClose} centered animation={false}>
          <Modal.Body>
            <Container>
              <Row>
                <Col>Start Time</Col>
                <Col>End Time</Col>
              </Row>
              <Row>
                <Col>
                  {range && (
                    <DateTimePicker
                      ref={onRef}
                      value={range[0]}
                      disableClock
                      maxDetail="minute"
                      format="dd/MM/yyyy HH:mm"
                      clearIcon={null}
                      onChange={value => {
                        handleRangeChange(value, undefined)
                      }}
                    />
                  )}
                </Col>
                <Col>
                  {range && (
                    <DateTimePicker
                      ref={onRef}
                      value={range[1]}
                      disableClock
                      maxDetail="minute"
                      format="dd/MM/yyyy HH:mm"
                      clearIcon={null}
                      onChange={value => {
                        handleRangeChange(undefined, value)
                      }}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex w-100">
              {isLoadingData ? (
                <div className="w-100 text-center">
                  <Spinner animation="border" className="mx-auto" />
                </div>
              ) : (
                ExportButton()
              )}
              <select
                className="form-control"
                value={exportFileType}
                onChange={e => setExportFileType(e.target.value)}
              >
                <option value=".csv">.csv</option>
                <option value=".xlsx">.xlsx</option>
              </select>
              <div className="ml-1"></div>
              <Button
                variant="secondary"
                onClick={handleClose}
                className="w-100"
              >
                Close
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    )
  } else {
    return (
      onlyModalMode ? null :
        <Button disabled {...props}>
          Export (Did not get sensor)
        </Button>
    )
  }
}
