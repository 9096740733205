import React, { useState } from "react"
import "./hamburger.css"

type HamburgerProps = {
  handleClick: Function
  isActive: Boolean
}

export default function Hamburger({ handleClick, isActive }: HamburgerProps) {
  //const [isActive, setIsActive] = useState(false)


  return (
    <div
      className={`burger-container ${isActive ? "change" : ""}`}
      onClick={() => handleClick()}
    >
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>

    </div>
  )
}
