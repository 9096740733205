import InformationTooltip from "../../../../components/InformationTooltip"
import {sortTable} from "../../../../functions/helpers.js"
import {Building, Device, DeviceDeployment, Organization, Room, Soundlevel, Vibration, DeviceMetric} from "../../../../types/dataTypes"
import {useEffect, useState} from "react";
import {getDeviceMetrics, getSoundlevels, getVibrations} from "../../../../api/api";
import {renderTime} from "../../../../functions/calculations";
import {useNavigate} from "react-router-dom";
import {Link} from "react-router-dom"

type DeviceListingProps = {
  devices: Device[],
  deviceDeployments?: DeviceDeployment[],
  buildings?: Building[],
  rooms?: Room[],
  organization: Organization | undefined
}

export default function DeviceListing({
                                        devices,
                                        deviceDeployments,
                                        buildings,
                                        rooms,
                                        organization
                                      }: DeviceListingProps) {
  const [soundlevels, setSoundlevels] = useState<Soundlevel[]>([])
  const [vibrations, setVibrations] = useState<Vibration[]>([])
  const [metrics, setMetrics] = useState<DeviceMetric[]>([])
  const dynamicPadding = "tw-px-2 lg:tw-px-6"

  const lastSeenHoursToCheck = 5

  const navigate = useNavigate()
  const navigateDeviceMetrics = (deviceId: string) => {
    navigate("/device/" + deviceId)
  }
  const navigateDeviceSettings = (deviceId: string) => {
    navigate("/device/" + deviceId + "/settings")
  }

  useEffect(() => {
    const updateSoundlevel = async (fromTime: Date) => {
      const soundlevelDevices = devices.filter(d => d.type?.model === "dB20")
      const lastSoundlevels = await getSoundlevels(soundlevelDevices.map(d=> d.id), [], fromTime, new Date()).catch(console.log)
      lastSoundlevels.sort((a: Soundlevel, b: Soundlevel) => b.end_time - a.end_time)
      setSoundlevels(lastSoundlevels)
    }
  
    const updateVibration = async (fromTime: Date) => {
      const vibrationDevices = devices.filter(d => d.type?.model === "RuuviTag")
      const lastVibrations = await getVibrations(vibrationDevices.map(d=> d.id), [], fromTime, new Date()).catch(console.log)
      if (lastVibrations !== undefined) {
        lastVibrations.sort((a: Vibration, b: Vibration) => b.end_time - a.end_time)
        setVibrations(lastVibrations)
      }
    }
  
    const updateMetrics = async (fromTime: Date) => {
      const gatewayDevices = devices.filter(d => d.type?.model === "KNOT")
      const lastMetrics = await getDeviceMetrics(gatewayDevices.map(d=> d.id), fromTime, new Date()).catch(console.log)
      lastMetrics.sort((a: DeviceMetric, b: DeviceMetric) => a.end_time ? b.end_time? - a.end_time : 0 : 0)
      setMetrics(lastMetrics)
    }

    if (devices) {
      const fromTime = new Date(new Date().getTime() - 60 * 60 * lastSeenHoursToCheck * 1000)
      updateSoundlevel(fromTime)
      updateVibration(fromTime)
      updateMetrics(fromTime)
    }
  }, [devices])

  let orgId = organization?.id ?? "unknown";

  const findDeviceTypeImage = (device: Device): string => {
    let type: string = device.type?.model ?? "unknown"
    if (type === "RuuviTag") {
      return "icon-device.svg"
    }
    if (type === "KNOT") {
      return "icon-router.svg"
    } else {
      return "icon-sound.svg"
    }
  }

  const findLastSeen = (device: Device): string => {
    if(device.type?.model === "dB20"){
      let soundlevel = soundlevels?.find(sl => sl.device_id === device.id)
      return soundlevel ? renderTime(soundlevel.end_time * 1000) : ""
    } else if (device.type?.model === "RuuviTag"){
      let vibration = vibrations?.find(v => v.device_id === device.id)
      return vibration ? renderTime(vibration.end_time * 1000) : ""
    } else if (device.type?.model === "KNOT"){
      let deviceMetrics = metrics?.find(m => m.device_id === device.id)
      return deviceMetrics ? renderTime(deviceMetrics.end_time! * 1000) : ""
    } else {
      return ""
    }
  }

  return (
    <div>
      <div className="">
        <table className="tw-w-full tw-text-left" id={orgId}>
          <thead
            className="tw-text-typography-muted tw-border-b tw-border-stroke-off-white tw-cursor-pointer">
          <tr>
            <th
              scope="col"
              className={`${dynamicPadding} md:tw-pl-6 tw-py-3 tw-font-light hover:tw-underline tw-hidden md:tw-table-cell`}
              onClick={() => sortTable(0, orgId)}
            >
              Type
            </th>
            <th
              scope="col"
              className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-font-light hover:tw-underline`}
              onClick={() => sortTable(1, orgId)}
            >
              Serial
            </th>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light hover:tw-underline"
                }`}
              onClick={() => sortTable(2, orgId)}
            >
              Building
            </th>
            <th
              scope="col"
              className={`${dynamicPadding} tw-py-3 tw-font-light hover:tw-underline tw-hidden md:tw-table-cell`}
              onClick={() => sortTable(3, orgId)}
            >
              Room
            </th>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light hover:tw-underline tw-hidden md:tw-table-cell`}
              onClick={() => sortTable(4, orgId)}
            >
              Last Seen
            </th>
            {/*<th*/}
            {/*    scope="col"*/}
            {/*    className={"tw-px-6 tw-py-3 tw-font-light tw-cursor-default lg:tw-table-cell"}>*/}
            {/*    Metrics*/}
            {/*</th>*/}
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light tw-cursor-default tw-hidden md:tw-table-cell`}>
              Grafana
            </th>
            <th
              scope="col"
              className={`${dynamicPadding} tw-pr-6 tw-py-3 tw-font-light tw-cursor-default `}>
              Settings
            </th>
          </tr>
          </thead>
          <tbody>
          {devices.map(device => {
            let deviceDeployment: DeviceDeployment | undefined = deviceDeployments?.find(dd => dd.device_id === device.id)
            let room: Room | undefined = rooms?.find(r => r.id === deviceDeployment?.room_id)
            let building: Building | undefined = buildings?.find(b => b.id === room?.building_id)
            return (
              <tr
                key={device.serial}
                className={`tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-b tw-border-stroke-off-white last:tw-border-none tw-font-bold`}
              >
                <td
                  className={`${dynamicPadding} md:tw-pl-6 tw-py-3 tw-flex tw-hidden md:tw-flex`}
                >
                  <div className="tw-flex tw-flex-nowrap tw-shrink-0 tw-h-full">

                    <img
                      src={
                        findDeviceTypeImage(device)
                      }
                      alt="device type icon"
                      className="tw-w-[20px] tw-mb-0.5 tw-inline-flex"
                    />
                    <span className="tw-pl-2">
                                            {device.type?.model ?? "Unknown"}
                                        </span>
                    {/* TODO: If adding more vibration devices the check below needs
                                        to account for that */}
                  </div>
                </td>
                <td
                  className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-break-all lg:tw-break-words`}
                >
                  {device.serial}
                </td>
                <td className={`${dynamicPadding}  tw-py-3 tw-break-all lg:tw-break-words`}>
                  {building ? (building.name
                    ? building.name
                    : building.address)
                    : ""}
                </td>
                <td className={`${dynamicPadding}  tw-py-3 tw-flex tw-break-words tw-hidden md:tw-flex`}>
                                    <span className="tw-pr-2">
                                        {room ? ( room.nice_name ? room.nice_name : room.name) : ""}
                                    </span>
                  {(deviceDeployment?.room_position ?? false) && (
                    <InformationTooltip>
                      {deviceDeployment?.room_position.description
                        ? deviceDeployment?.room_position.description
                        : ""}
                    </InformationTooltip>
                  )}
                </td>
                <td
                  className={`${dynamicPadding}  tw-py-3 tw-break-words tw-hidden md:tw-table-cell`}
                >
                  {findLastSeen(device)}
                </td>
                {/*<td*/}
                {/*    className={`tw-px-6 tw-py-3 tw-pl-11 tw-cursor-pointer`}*/}
                {/*>*/}
                {/*    <img src="icon-arrow.svg" alt="arrow icon"*/}
                {/*         onClick={() => navigateDeviceMetrics(device.id)}/>*/}
                {/*</td>*/}
                <td
                  className={`${dynamicPadding}  tw-py-3 tw-cursor-pointer tw-hidden md:tw-table-cell`}
                >
                  <Link to={"/device/" + device.id}>
                    <img src="icon-arrow.svg" alt="arrow icon"/>
                  </Link>
                </td>
                <td
                  className={`${dynamicPadding} tw-pr-6 tw-py-3 tw-cursor-pointer tw-flex`}
                >
                  <img src="icon-settings.svg" alt="arrow icon" onClick={() => navigateDeviceSettings(device.id)}/>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
