import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom"
import { getAccountWithAbort } from "../../api/api";

type ProtectedRouteProps = {
    outlet: JSX.Element,
    redirectPath?: string
}

enum AuthStatus {
    Unknown,
    Unauthorized,
    Authorized
}

export default function ProtectedRoute({ outlet, redirectPath }: ProtectedRouteProps) {
 
    const [isAuthenticated, setIsAuthenticated] = useState(AuthStatus.Unknown);
    const authenticationPath = "/login";

    useEffect(() => {
        let abortController = new AbortController();
        const { signal } = abortController;

        getAccountWithAbort(signal)
            .then((res) => {
                setIsAuthenticated(AuthStatus.Authorized);
            })
            .catch((err) => {
                console.log("Failed to fetch account.");
                setIsAuthenticated(AuthStatus.Unauthorized);
            })
    }, [])


    if (isAuthenticated === AuthStatus.Authorized) {
        return (outlet);
    }
    else if (isAuthenticated === AuthStatus.Unauthorized) {
        return (<Navigate to={{ pathname: authenticationPath }} />)
    }
    else if (isAuthenticated === AuthStatus.Unknown) {
        return (<div>Loading...</div>)
    }
    else {
        return (<Navigate to={{ pathname: authenticationPath }} />) // This case should never occur, but it functions as a failback
    }

}