import React from "react"

type AnalyticsTileProps = {
  onClick?: Function
  data?: any
  title: string
}

export default function AnalyticsTile({
  onClick,
  data,
  title,
}: AnalyticsTileProps) {

  const unitsOfMeasurement: Record<string, string> = {
    decibel: "dB",
    temperature: "°C",
    vibration: "G",
    humidity: "%"
  }

  const getUnit = (type: string) => {
    type = type.toLowerCase();
    return unitsOfMeasurement[type] !== undefined ? unitsOfMeasurement[type] : ""
  }

  return (
    <div className="tw-relative tw-align-middle tw-text-center">
      <div className="">
        <div className="tw-text-typography-muted tw-pt-7">{title}</div>
        <div className="tw-font-semibold tw-text-42 tw-pb-7">
          {data
            ?
            data + " " + getUnit(title)
            :
            ""}
        </div>
      </div>
    </div>
  )
}
