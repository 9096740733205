import { cloneDeep } from "lodash"
import { currentMonthRange } from "./time"
import moment from "moment"

export const csvFormat = (data, range = currentMonthRange(), devices) => {
  
  // Convert to milliseconds
  if (data) {
    let mutatedData = cloneDeep(data)
    let formattedData = []

    mutatedData.map(row => {
      const device = devices[devices.findIndex(device => device.id === row.device_id)]

      const endTime = row.end_time * 1000
      const startTime = endTime - row.period * 1000
      const serial = device ? device.serial : "unknown" //should never happen
      const nickname = device.nickname ? device.nickname : undefined;
      const lafmin = row.lafmin
      const lafmax = row.lafmax
      const leq = row.leq

      let formattedRow = new Object()

      // The order of the properties in the CSV file is defined by
      // when the properties were added.
      // Therefore we create a new object in the wanted order

      formattedRow.date = startTime
      if(nickname){
        //only include nickname if the device has one
        formattedRow.nickname = nickname;
      }
      formattedRow.serial = serial
      formattedRow.start_time = startTime
      formattedRow.end_time = endTime
      formattedRow.lafmax = lafmax
      formattedRow.lafmin = lafmin
      formattedRow.leq = leq

      formattedData.push(formattedRow)
    })

    // Filter by range
    formattedData = formattedData.filter(row => {
      return (
        moment(row.end_time).isAfter(range[0]) &&
        moment(row.end_time).isBefore(range[1])
      )
    })

    const dateFormat = "YYYY-MM-DD HH:mm:ss"
    formattedData.map(row => {
      row.end_time = moment(row.end_time).format(dateFormat)
      row.start_time = moment(row.start_time).format(dateFormat)
      row.date = moment(row.date).format("YYYY-MM-DD")
    })

    return formattedData
  }
}
