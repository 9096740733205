import React, { createContext, useContext, useEffect, useState } from "react";
import Toast from "../components/Toast";

export const ToastContext = createContext((toastText: string, toastTimeout: number, toastType?: string) => { return });

type ToastContextWrapperProps = {
    children: JSX.Element
}

/*
 * This is a wrapper that holds the state of toasts. It should be used as a parent on top of the project.
 * When a toast is requested, the Toast component will be displayed for the requested time. 
 * 
 * The toast context (ToastContext) must be imported and instantiated in the child class that requests a toast. The instantiated name is used as the function to call. 
 * The context can be instantiated and called on as follows, with a message of "Message!", timeout of 5 sec and a color of success.
 * 
 * const toastContext = useContext(ToastContext)
 * toastContext("Message!", 5, "success") 
 */
export default function ToastContextWrapper({ children }: ToastContextWrapperProps) {
    const [toastVisible, setToastVisible] = useState(false);
    const [toastText, setToastText] = useState<string>("");
    const [toastTimeout, setToastTimeout] = useState<number>(0);
    const [toastType, setToastType] = useState<string>("");

    const setToast = (toastText: string, toastTimeout: number, toastType = "unkown") => {
        setToastText(toastText);
        setToastTimeout(toastTimeout);
        setToastType(toastType);
        setToastVisible(true);
    }


    useEffect(() => {
        if (toastVisible) {
            let timer = setTimeout(() => setToastVisible(false), 1000 * toastTimeout);

            return () => {
                clearTimeout(timer);
            };
        }
    }, [toastVisible])

    return (
        <ToastContext.Provider
            value={
                setToast
            }>
            {toastVisible &&
                <Toast text={toastText} type={toastType}/>}
            {children}
        </ToastContext.Provider>
    )
}
