import Titlebar from "../../components/Titlebar";
import {TempState} from "../../types/componentTypes";
import {useEffect, useState} from "react";
import {Device, DeviceStatus, ModelTemplate} from "../../types/dataTypes";
import {getActiveModelTemplates, getDeviceConditionCached} from "../../api/api";
import Alarm from "./components/Alarm";
import ReactPaginate from "react-paginate";
import {useLocation, useNavigate, useParams} from "react-router";
import Button from "../../components/Button";


type MainState =
  {
    state: TempState
  }

const AlarmPage = ({deviceConditions, anomalyProbabillityVisible}: {
  deviceConditions: DeviceStatus[],
  anomalyProbabillityVisible: boolean
}) => {
  return <div className="tw-w-full">
    {deviceConditions.map(dc => <Alarm device={dc.device} status={dc} key={dc.device.id}
                                       anomalyProbabillityVisible={anomalyProbabillityVisible}/>)}
  </div>
}


function PaginatedAlarms({
                           devicesPerPage,
                           deviceConditions, anomalyProbabillityVisible
                         }: { devicesPerPage: number, deviceConditions: DeviceStatus[], anomalyProbabillityVisible: boolean }) {

  // We start with an empty list of items.
  const [currentDeviceConditions, setCurrentDeviceConditions] = useState<DeviceStatus[]>([]);
  const [pageCount, setPageCount] = useState<number>();
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState<number>();

  const location = useLocation()
  const navigate = useNavigate()

  const queryParams = new URLSearchParams(location.search)

  useEffect(() => {
    if (deviceConditions.length > 0 && devicesPerPage > 0) {
      setPageCount(Math.ceil(deviceConditions.length / devicesPerPage));
    }
  }, [deviceConditions])

  useEffect(() => {
    if (pageCount !== undefined && pageCount > 0) {

      const urlPage = queryParams.get('page')
      let page = Number(urlPage)
      if (urlPage === undefined || urlPage === null || isNaN(page)) {
        handleChangePage(0)
      }
      if (page > 0 && (page - 1) !== currentPage) {
        handleChangePage(page - 1)
      }

    }
  }, [pageCount])

  useEffect(() => {
    setCurrentDeviceConditions(deviceConditions.slice(itemOffset, itemOffset + devicesPerPage))
  }, [itemOffset, currentPage])


  // Invoke when user click to request another page.
  const handlePageClick = (event: { selected: number }) => {
    handleChangePage(event.selected)
  };

  const handleChangePage = (page: number) => {
    const newOffset = page * devicesPerPage % deviceConditions.length;
    setItemOffset(newOffset);
    setCurrentPage(page)
    navigate("/alarmdashboard?page=" + (page + 1))
  }

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-w-full tw-mt-5">
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount ?? 0}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="tw-flex"
        activeClassName="active"
        // @ts-ignore
        renderOnZeroPageCount={null}
        forcePage={currentPage}
        onPageActive={e => console.log(e)}
      />
      <AlarmPage deviceConditions={currentDeviceConditions}
                 anomalyProbabillityVisible={anomalyProbabillityVisible}></AlarmPage>
      <ReactPaginate
        nextLabel="next >"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={2}
        pageCount={pageCount ?? 0}
        previousLabel="< previous"
        pageClassName="page-item"
        pageLinkClassName="page-link"
        previousClassName="page-item"
        previousLinkClassName="page-link"
        nextClassName="page-item"
        nextLinkClassName="page-link"
        breakLabel="..."
        breakClassName="page-item"
        breakLinkClassName="page-link"
        containerClassName="tw-flex"
        activeClassName="active"
        // @ts-ignore
        renderOnZeroPageCount={null}
        forcePage={currentPage}
        onPageActive={e => console.log(e)}
      />
    </div>
  );
}

export default function AlarmDashboard({state}: MainState) {
  const [devices, setDevices] = useState<Device[]>([])
  const [deviceDataLoading, setDeviceDataLoading] = useState(true)
  const [deviceConditions, setDeviceConditions] = useState<DeviceStatus[]>([])
  const [serialSearchTerm, setSerialSearchTerm] = useState<string>("");
  const [anomalyProbabillityVisible, setAnomalyProbabillityVisible] = useState<boolean>(false)

  useEffect(() => {
    /** Update devices from state to include only devices that are currently active in a model template **/
    const fetchDevices = async () => {
      setDeviceDataLoading(true)
      if (state.deviceDeployments?.length ?? 0 > 0) {
        try {
          setDeviceDataLoading(true)
          let data = await getActiveCMDevices(state.devices)
          // Remove devices that don't have an organization
          setDevices(data)
        } catch (err) {
          setDeviceDataLoading(false)
        }
      }
    }
    fetchDevices()
    return () => {
      setDevices([])
    }
  }, [state])

  useEffect(() => {
    const fetchDeviceConditions = async () => {
      const result: DeviceStatus[] = await getDeviceConditionCached()
      setDeviceConditions(result.filter(dc => dc.status === 'CRITICAL' || dc.status === 'WARNING'))
    }
    devices && fetchDeviceConditions()
  }, [devices])

  const getActiveCMDevices = async (devices: Device[]) => {
    // Get active templates
    const templates = await getActiveModelTemplates()
    let activeDeviceIds: string[] = []
    templates.forEach((t: ModelTemplate) => {
      t.devices?.forEach((d: Device) => {
        if (!activeDeviceIds.includes(d.id)) {
          activeDeviceIds.push(d.id)
        }
      })
    })

    return devices.filter(d => activeDeviceIds.includes(d.id))
  }

  return (
    <>
      <Titlebar headline="Manage alarms"/>
      <div className={"tw-flex tw-flex-col tw-items-center"}>
        <input type='text' value={serialSearchTerm} placeholder={"Filter by serial number"}
               onChange={e => setSerialSearchTerm(e.target.value)}
               className={"tw-mx-10 tw-mt-10 tw-text-center tw-h-[60px] tw-rounded-full tw-w-1/2"}/>
        <div className={"tw-flex-row"}>
          <p className={"tw-inline-block tw-mr-2"}>Show Probabillity: </p>
          <input type={"checkbox"} onChange={() => setAnomalyProbabillityVisible(!anomalyProbabillityVisible)}
                 checked={anomalyProbabillityVisible}/>
        </div>
        <PaginatedAlarms devicesPerPage={5} anomalyProbabillityVisible={anomalyProbabillityVisible}
                         deviceConditions={deviceConditions.filter(dc => dc.device.serial.includes(serialSearchTerm))}/>
      </div>
    </>
  )
}