import { useEffect, useState } from "react"
import { Link, useLocation, useNavigate, } from "react-router-dom"
import {SidebarElement, TempState} from "../../types/componentTypes"
import Logo from "../Logo/Logo"
import Hamburger from "./Hamburger"
import "./sidebar.css"

// TODO: UX - For usability reasons the names of pages should appear on hover - on hover icon or expanding sidebar?
export default function Sidebar(props: {state: TempState}) {
  const [hamburgerIsActive, setHamburgerIsActive] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [activePathname, setActivePathname] = useState<string>()
  const location = useLocation();

  useEffect(() => {
    setActivePathname(location.pathname)
  }, [location])

  const handleLogout = (logoutLink: string) => {
    // Using legacy navigation as logout is not implemented with fetch()
    window.location.replace(window.origin + logoutLink)
  }

  const handleHamburgerClick = () => {
    setHamburgerIsActive(!hamburgerIsActive)
    setIsOpen(!isOpen)
  }

  const viewableElements = sidebarElements.filter(e => {
                  return !e.adminOnly || props.state.userAccount?.admin
                })
  return (
    <>
      {/*For larger screens */}
      <div className="tw-hidden sm:tw-block">
        <aside
          className="tw-h-full tw-w-[80px] tw-fixed tw-z-10 tw-top-0 tw-left-0 tw-bg-typography-light"
          aria-label="sidebar"
        >
          <div className="tw-h-full tw-overflow-y-auto tw-overflow-hidden tw-py-7 tw-px-3 tw-border-r-[1px] tw-border-stroke-off-white">
            <div className="tw-m-auto tw-pt-4 tw-max-w-[46px]">
              <Logo
                firstElement={viewableElements[0]}
              ></Logo>
            </div>
            <ul className="last:tw-mt-16">
              {viewableElements &&
                viewableElements.map((icon, index) => (
                  <div className="tw-group tw-h-12" key={icon.link}>
                    <div className="tw-flex">
                      <li
                        title={icon.label}
                        onClick={() =>
                          index === 5
                          && handleLogout(
                            viewableElements[viewableElements.length - 1].link
                          )
                        }
                      >
                        <div
                          className={`tw-h-8 tw-w-8 tw-flex tw-flex-row tw-rounded tw-transform -tw-translate-x-10 group-hover:tw-bg-primary-soft-green/75 ${activePathname === icon.link ? "tw-bg-primary-soft-green" : ""
                            }`}
                        ></div>
                        <div className="tw-flex tw-flex-row tw-transform tw-translate-x-4 -tw-translate-y-7">
                          <Link to={icon.link}>
                            <img
                              src={icon.icon.default}
                              alt={icon.label}
                              className="tw-w-[25px] tw-m-auto group-hover:tw-hidden tw-inline-block"
                            />
                            <img
                              src={icon.icon.active}
                              alt={icon.label}
                              className="tw-w-[25px] tw-m-auto tw-hidden group-hover:tw-inline-block"
                            />
                          </Link>
                        </div>
                      </li>
                    </div>
                  </div>
                ))}
            </ul>
          </div>
        </aside>
      </div>
      {/* TODO: Merge this with the block above, no need to have them separate. Just use TW breakpoints */}
      {/*For smaller screens */}
      <div className="sm:tw-hidden">
        <aside
          className="tw-h-[70px] tw-w-screen tw-z-10 tw-top-0 tw-flex tw-sticky tw-border-b tw-bg-typography-light"
          aria-label="sidebar"
        >
          <div className="tw-max-w-[46px] tw-my-auto tw-ml-4">
            <Logo
              firstElement={sidebarElements[0]}
            ></Logo>
          </div>
          <div className={`${isOpen ? "change" : "header-container"}`}>
            <Hamburger handleClick={handleHamburgerClick} isActive={hamburgerIsActive}></Hamburger>
          </div>
          {isOpen && (
            <ul className="last:tw-mt-16 tw-h-fit tw-pb-3 tw-w-screen tw-bg-typography-light tw-rounded-b-normal tw-border-b -tw-translate-x-8 tw-translate-y-[0.4rem]">
              {sidebarElements &&
                sidebarElements.map((icon, index) => (
                  <div className="tw-group tw-h-12" key={icon.link}>
                    <div className="tw-flex">
                      <li
                        title={icon.label}
                        onClick={() =>
                          index === 5
                          && handleLogout(
                            sidebarElements[sidebarElements.length - 1].link
                          )
                        }
                      >
                        <div className="tw-flex tw-flex-row tw-h-8 tw-w-full tw-transform tw-translate-x-4 tw-translate-y-4">
                          <Link to={icon.link} onClick={handleHamburgerClick}>
                            <img
                              src={icon.icon.default}
                              alt={icon.label}
                              className="tw-w-[25px] tw-m-auto group-hover:tw-hidden tw-inline-block"
                            />
                            <img
                              src={icon.icon.active}
                              alt={icon.label}
                              className="tw-w-[25px] tw-m-auto tw-hidden group-hover:tw-inline-block"
                            />
                            <div className="tw-flex tw-flex-col tw-transform tw-translate-x-4 -tw-translate-y-7">
                              <p className="tw-flex tw-flex-col tw-w-full tw-transform tw-translate-x-10 tw-translate-y-[0.3rem] tw-text-typography-muted">
                                {icon.label}
                              </p>
                            </div>
                          </Link>
                        </div>
                      </li>
                    </div>
                  </div>
                ))}
            </ul>
          )}
        </aside>
      </div>
    </>
  )
}

export const sidebarElements: SidebarElement[] = [
  {
    icon: {
      default: "icon-charts.svg",
      active: "icon-charts-active.svg",
    },
    label: "Condition Monitoring",
    link: "/",
  },
  {
    icon: {
      default: "alarm-bell-svgrepo-com.svg",
      active: "alarm-bell-svgrepo-com.svg"
    },
    label: "Alarm Overview",
    link: "/alarm",
  },
  {
    icon: {
      default: "icon-device.svg",
      active: "icon-device-active.svg",
    },
    label: "Devices",
    link: "/devices",
  },
  {
    icon: {
      default: "icon-account.svg",
      active: "icon-account-active.svg",
    },
    label: "Account",
    link: "/account",
  },
  {
    icon: {
      default: "alarm-bell-svgrepo-com.svg",
      active: "alarm-bell-svgrepo-com.svg"
    },
    label: "Alarms",
    link: "/alarmdashboard",
    adminOnly: true
  },
  {
    icon: {
      default: "icon-logout.svg",
      active: "icon-logout-active.svg",
    },
    label: "Logout",
    link: "/logout",
  },
]
