import {Alarm} from "../../../types/dataTypes";
import {sortTable} from "../../../functions/tableSort";
import TrafficLight from "../../../components/TrafficLight";
import {useNavigate} from "react-router";
import {AlarmRow} from "../AlarmOverview";
import {format, parse} from "date-fns"

export function AlarmList({
                            alarmRows
                          }: {alarmRows: AlarmRow[]
}) {

  const navigate = useNavigate()

  const dynamicPadding = "tw-px-2 md:tw-px-6"

  function navigateToAlarm (alarm: Alarm) {
    navigate(alarm.id)
  }
  
  const dateFormatString = "PP kk:mm"

  const handleDateSort = (a: string, b: string) => {
    let reference = new Date()
    return parse(a, dateFormatString, reference).getTime() - parse(b, dateFormatString, new Date()).getTime()
  }


  return (
    <div>
      <div className="">
        <table className="tw-w-full tw-text-left" id={"alarm"}>
          <thead className="tw-text-typography-muted tw-border-b tw-border-stroke-off-white tw-cursor-pointer">
          <tr>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light hover:tw-underline`}
              onClick={() => sortTable(0, "alarm")}
            >
              Status
            </th>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light hover:tw-underline`}
              onClick={() => sortTable(1, "alarm", handleDateSort)}
            >
              Date
            </th>
            <th
              scope="col"
              className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-font-light hover:tw-underline`}
              onClick={() => sortTable(2, "alarm")}
            >
              Type
            </th>
            <th
              scope="col"
              className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-font-light hover:tw-underline`}
              onClick={() => sortTable(3, "alarm")}
            >
              Device
            </th>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light hover:tw-underline`}
              onClick={() => sortTable(4, "alarm")}
            >
              Asset
            </th>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light hover:tw-underline tw-hidden md:tw-table-cell`}
              onClick={() => sortTable(5, "alarm")}
            >
              Room
            </th>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light hover:tw-underline tw-hidden md:tw-table-cell`}
              onClick={() => sortTable(6, "alarm")}
            >
              Building
            </th>
            <th
              scope="col"
              className={`${dynamicPadding}  tw-py-3 tw-font-light tw-cursor-default tw-hidden md:tw-table-cell`}
            >
              More
            </th>
          </tr>
          </thead>
          <tbody>
          {alarmRows.map((row) => {

            return (
              <tr
                key={row.alarm.id}
                className={`tw-bg-white hover:tw-bg-secondary-darker-off-white hover:tw-bg-opacity-30 tw-border-b tw-border-stroke-off-white last:tw-border-none tw-font-bold tw-text-sm`}
                onClick={() => navigateToAlarm(row.alarm)}>
                <td className={`${dynamicPadding}  tw-py-3`}>
                  <p className={row.alarm.resolved ? "tw-text-green-400" : "tw-text-red-400"}>{row.alarm.resolved ? "Resolved" : "Unresolved"}</p>
                </td>
                <td className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-break-all lg:tw-break-words`}>
                    {format(new Date(row.alarm.timestamp * 1000), dateFormatString)}
                </td>
                <td className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-break-all lg:tw-break-words`}>
                  {row.alarm.alarm_type}
                </td>
                <td className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-break-all lg:tw-break-words`}>
                {row.device ? row.device.serial : "UNKNOWN"}
              </td>
                <td className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-break-all lg:tw-break-words`}>
                  {row.deployment && row.deployment.assets.length === 1 ? row.deployment.assets[0].nice_name ??
                    row.deployment.assets[0].name : "UNKNOWN"}
                </td>
                <td className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-break-all lg:tw-break-words tw-hidden md:tw-table-cell`}>
                  {row.room ? row.room.nice_name ?? row.room.name : "UNKNOWN"}
                </td>
                <td className={`${dynamicPadding} tw-pl-6 tw-py-3 tw-break-all lg:tw-break-words tw-hidden md:tw-table-cell`}>
                  {row.building ? row.building.name ?? row.building.address : "UNKNOWN"}
                </td>
                <td
                  className={`${dynamicPadding}  tw-py-3 tw-pl-11 tw-cursor-pointer tw-hidden md:tw-table-cell
                `}
                  onClick={() => {}}
                >
                  <img src="icon-arrow.svg" alt="arrow icon"></img>
                </td>
              </tr>
            )
          })}
          </tbody>
        </table>
      </div>
    </div>
  )
}
