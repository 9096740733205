/* DEVICELIST */

export const sortTable = (n: number, orgTable: string, sortMethod?: (a: string, b: string) => number) => {
  var table: HTMLTableElement,
    rows: HTMLCollectionOf<HTMLTableRowElement>,
    switching,
    i,
    x,
    y,
    shouldSwitch,
    dir,
    switchcount = 0;
  table = document.getElementById(orgTable) as HTMLTableElement
  switching = true
  dir = "asc"

  while (switching) {
    switching = false
    rows = table.rows
    for (i = 1; i < rows.length - 1; i++) {
      shouldSwitch = false
      x = rows[i].getElementsByTagName("TD")[n]
      y = rows[i + 1].getElementsByTagName("TD")[n]

      if (sortMethod === undefined) {
        sortMethod = (a, b) => a.localeCompare(b)
      }
      if (dir === "asc") {
        if (sortMethod(x.innerHTML.toLowerCase(), y.innerHTML.toLowerCase()) > 0) {
          shouldSwitch = true
          break
        }
      } else if (dir === "desc") {
        if (sortMethod(x.innerHTML.toLowerCase(), y.innerHTML.toLowerCase()) < 0) {
          shouldSwitch = true
          break
        }
      }
    }

    if (shouldSwitch) {
      rows[i].parentNode?.insertBefore(rows[i + 1], rows[i])
      switching = true
      switchcount++
    } else {
      if (switchcount === 0 && dir === "asc") {
        dir = "desc"
        switching = true
      }
    }
  }
}
