import React from "react";
import SectionWrapper from "../SectionWrapper";

const terminologyDescriptions = {
    "Averaging time":
        "Each point in the 'Average' graph below will have the average value of all LAeq soundlevels within the specified interval.",
    Threshold:
        "Configurable upper dB limit. The amount of minutes above this threshold per day is shown in the third panel.",
    LAeq: "Average soundlevel.",
    LAFmin: "Lowest recorded soundlevel",
    LAFmax: "Highest recorded soundlevel",
    "LA and LAF":
        "Describes how the levels are weighted to represent what humans hear.",
}
//TODO: Get breakpoint from tailwind config
const mdScreenBreakpoint = 768;
const lgScreenBreakpoint = 1024;

type GrafanaTerminologyProps = {
    onClose?: Function
}
export default function GrafanaTerminology({ onClose = () => { } }: GrafanaTerminologyProps) {


    return (
        <div className="tw-p-10 lg:tw-mx-36 tw-max-w-[1000px]">
            <SectionWrapper styles={`tw-p-6 tw-border `}>
                <header className="tw-flex tw-flex-row tw-rounded-normal tw-items-center tw-p-2">
                    <span className="tw-font-bold tw-text-24 tw-leading-24 ">
                        Terminology
                    </span>
                    <div
                        className="tw-ml-auto"
                        onClick={() => onClose()}        >

                        <img
                            src="icon-cross.svg"
                            alt="exit icon"
                            className="tw-cursor-pointer"
                        />
                    </div>
                </header>
                <div className="tw-p-2">
                    {terminologyDescriptions &&
                        Object.entries(terminologyDescriptions).map(([key, value]) => {
                            return (
                                <div key={key} className="tw-grid tw-grid-cols-3 tw-gap-4">
                                    <div className="tw-font-bold">
                                        {(key)}:
                                    </div>
                                    <div className="tw-col-span-2 tw-break-words">{value.toString()}</div>
                                    <div></div>
                                </div>
                            )
                        })}
                </div>
            </SectionWrapper>
        </div>
    )
}
