import {useNavigate, useParams} from "react-router";
import {TempState} from "../../types/componentTypes";
import {Alarm as AlarmType} from "../../types/dataTypes"
import {useEffect, useState} from "react";
import {getAlarm} from "../../api/api";
import AlarmDialog from "./Components/AlarmDialog";

type AlarmProps = {
  state: TempState
}

export const Alarm = ({state}: AlarmProps) => {
  const params = useParams()
  const alarm_id = params.alarm_id

  const [alarm, setAlarm] = useState<AlarmType>()
  const navigate = useNavigate()

  useEffect( () => {
    const fetchAlarm = async () => {
      let alarm: AlarmType = await getAlarm(alarm_id).catch((message) => {console.log(message); navigate("/404")})
      setAlarm(alarm)}
    fetchAlarm()
  }, [])



  return (
    alarm ?
      <AlarmDialog alarm={alarm} tempState={state}/>
    : <div>loading...</div>
  )
}