import React from "react"

let grafanaUrl = window._env_.GRAFANA_URL ? window._env_.GRAFANA_URL : process.env.GRAFANA_URL;

if(grafanaUrl === undefined){
  grafanaUrl = "http://localhost:3000"
}

export default ({ dashboardId, templateVariables }) => {
  /*
    Embeds grafana in an iframe.
    Opens the dashboard with id {dashboardId}
    appends {variables} to url to feed template variables in grafana.

    Offsets the iframe to the left and hides overflow to hide the sidebar in grafana.
    This is done because we don't want the user to browser other dashboards through this,
    but we still want template variables visible, which isn't possible in kiosk mode.
  */

  let url = `${grafanaUrl}/d/${dashboardId}?`

  Object.keys(templateVariables).forEach(varName => {
    if (!url.endsWith("?")) {
      url += "&"
    }
    url += `var-${varName}=${templateVariables[varName]}`
  })

  const onMobile = window.innerWidth < 769
  return (
    <div style={{ height: "100%", overflow: "hidden" }}>
      <iframe
        src={url}
        frameBorder="0"
        style={{
          height: "100%",
          //On mobile sizes the grafana sidebar is gone, so use 100% in that case.
          width: onMobile ? "100%" : "calc(100% + 60px)",
          marginLeft: onMobile ? "auto" : "-60px",
        }}
      ></iframe>
    </div>
  )
}
