import React from "react"

type SectionWrapperProps = {
  children: React.ReactNode
  styles?: string
}

// A wrapper component consisting of a border and a white background for different elements on screen
const SectionWrapper = React.forwardRef<HTMLInputElement, SectionWrapperProps>(
  ({ children, styles }: SectionWrapperProps, ref: React.Ref<HTMLInputElement>) => {
    return (
      <div
        ref={ref}
        className={`xs:tw-rounded-normal tw-bg-typography-light tw-border-stroke-off-white ${styles}`}
      >
        {children}
      </div>
    )
  })

export default SectionWrapper;
