import {Alarm, AnomalyDetectionResult, AnomalyGraphDataMap, Device, ModelTemplate} from "../../types/dataTypes";
import {PlotParams} from "react-plotly.js";
import {Data, PlotMouseEvent, Layout} from "plotly.js";
import RangeAwarePlot from "../RangeAwarePlot";
import {useEffect, useState} from "react";
import Button from "../Button";
import SimpleDateSelect from "./Components";
import {SimpleGraphDates, simpleGraphTimeConverter} from "./Components/SimpleDateSelect";
import * as Plotly from "plotly.js";
import {useNavigate} from "react-router";


type AnomalyGraphProps = {
  device: Device
  modelTemplates: ModelTemplate[]
  anomalyGraphDataMap: AnomalyGraphDataMap
  alarms: Alarm[]
  onGraphClick?: (timestamp: number | undefined) => Data | undefined
  hovermode?: Layout["hovermode"]
  className?: string
  selectedGraphTime?: SimpleGraphDates
  onUpdateGraphTime?: (selectedTime: SimpleGraphDates) => void
  graphEndTime?: Date
  navigateToAlarmOnClick?: boolean
}

function mapFeatureToCustomerFacing(feature: string): string {
  return {
    "leq": "Soundlevel",
    "vib_mag": "Vibration"
  }[feature] ?? feature
}


export function AnomalyGraph({
                               device,
                               modelTemplates,
                               anomalyGraphDataMap,
                               alarms,
                               hovermode = "closest",
                               onGraphClick,
                               className,
                               selectedGraphTime,
                               onUpdateGraphTime,
                               graphEndTime,
                               navigateToAlarmOnClick = true
                             }: AnomalyGraphProps) {

  const navigate = useNavigate()

  const [plots, setPlots] = useState<{ [modelName: string]: JSX.Element }>()
  const [selectedModel, setSelectedModel] = useState<string>()
  const [modelNames, setModelNames] = useState<Set<string>>()


  useEffect(() => {
    // Find all groups of model templates
    if (modelTemplates.length > 0 &&  modelTemplates.every(mt => Object.keys(anomalyGraphDataMap).includes(mt.id))) {
      let modelTemplatesWithResults: ModelTemplate[] = modelTemplates.filter(mt => anomalyGraphDataMap[mt.id].length > 0)
      let modelNamesWithResults = new Set<string>(modelTemplatesWithResults.map(mt => mt.name))
      setModelNames(modelNamesWithResults);
      if ((selectedModel === undefined || !Array.from(modelNamesWithResults).includes(selectedModel)) && modelTemplatesWithResults.length > 0) {
        setSelectedModel(Array.from(modelNamesWithResults)[0])
    }
    }
  }, [modelTemplates, anomalyGraphDataMap, selectedModel])


  useEffect(() => {
    let tempPlots: { [modelName: string]: JSX.Element } = {}

    // Sort model templates into groups by name, and stitch the results together
    modelNames !== undefined && modelTemplates.every(mt => Object.keys(anomalyGraphDataMap).includes(mt.id)) && modelNames.forEach(mn => {
        let adr: AnomalyDetectionResult[] = []
        let templatesInGroup = modelTemplates.filter(mt => mt.name === mn)
        templatesInGroup.forEach(mt => {
          adr.push(...anomalyGraphDataMap[mt.id].filter(result => {
            if (templatesInGroup.length > 1) {
              if (mt.end_time !== undefined && mt.end_time !== null) {
                return result.end_time < mt.end_time && result.end_time >= mt.start_time
              } else {
                return result.end_time >= mt.start_time
              }
            }
            return true
          }))
        })
        // If data after stitching, draw plot
        if (adr.length > 0) {
          let feature = adr[adr.length -1 ].feature
          tempPlots[mn] = (<RangeAwarePlot
              {...plotifyData(feature as string, adr, alarms)}
              onClick={(e) => {
                handleGraphUpdateAfterClick(feature, onGraphClick !== undefined ? onGraphClick(handleGraphClick(e)) : undefined);
                navigateAlarmOnClick(e);
              }}
              key={`${device.id}-${feature}`}
              className={"tw-w-full"}
            />
          )
        }
      }
    )
    setPlots(tempPlots)
  }, [modelNames, selectedGraphTime, alarms, anomalyGraphDataMap, device, modelTemplates, onGraphClick])


  // Utils
  function hasMeta(object: any) {
    return 'meta' in object
  }

  function getMeta(object: any) {
    return object.meta
  }

  function mapModelNameToNiceName(modelName: string) {
    //Find latest version in group, and use that nicename to show to customer
    let modelsInGroup = modelTemplates.filter(mt => mt.name == modelName)
    if(modelsInGroup.length > 0){
      modelsInGroup.sort((m1, m2) => m2.version - m1.version)
      return modelsInGroup[0].nice_name ?? modelName
    }
    else return ""
  }

  const formatDate = (date: Date) => {
    const pad = (a: number) => {
      if (a >= 10) {
        return `${a}`
      } else {
        return `0${a}`
      }
    }
    const formatted = `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(date.getDate())} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(date.getSeconds())}`
    return formatted
  }

  // Make plot
  const plotifyData = (feature: string, anomalyDetectionResults: AnomalyDetectionResult[], alarms: Alarm[]): PlotParams => {

    const plotData: Data[] = []

    const data = anomalyDetectionResults.sort((a, b) => {
      return a.end_time - b.end_time
    });
    const x = data.map(adr => formatDate((new Date(adr.end_time * 1000)))
    )
    const y = data.map(adr => adr.value ?? null)


    const normalLower = data.map(adr => adr.normal_lower ?? null)
    const normalUpper = data.map(adr => adr.normal_upper ?? null)
    const normalLowerNumbers = normalLower.filter(v => typeof v === "number") as number[];
    const normalUpperNumbers = normalUpper.filter(v => typeof v === "number") as number[];
    const yNumbers = y.filter(v => typeof v === "number") as number[];
    const yLowerRange = Math.min(Math.min(...yNumbers), Math.min(...(normalLowerNumbers.length > 0 ? normalLowerNumbers : yNumbers)))
    const yUpperRange = Math.max(Math.max(...yNumbers), Math.max(...(normalUpperNumbers.length > 0 ? normalUpperNumbers : yNumbers)))

    const normalBandColor = 'rgba(159,226,16,0.8)'

    // plot continuous subarrays
    let continuousX: string[] = []
    let continuousLower: number[] = []
    let continuousUpper: number[] = []
    let showlegend = true;
    normalLower.forEach((lowerValue, index) => {
      if (typeof lowerValue === 'number' && normalUpper[index]) {
        continuousLower.push(lowerValue)
        continuousUpper.push(normalUpper[index] as number)
        continuousX.push(x[index])
      } else {
        if (continuousLower.length > 0) {
          plotData.push({
              x: continuousX,
              y: continuousLower,
              name: "Normal lower",
              fill: 'tozeroy',
              fillcolor: 'transparent',
              marker: {
                color: 'green'
              },
              legendgroup: 'normal bands',
              showlegend
            },
            {
              x: continuousX,
              y: continuousUpper,
              fill: "tonexty",
              fillcolor: normalBandColor,
              marker: {
                color: 'green'
              },
              name: "Normal upper",
              legendgroup: 'normal bands',
              showlegend
            })
          continuousX = [];
          continuousUpper = []
          continuousLower = []
          showlegend = false;
        }
      }
    })


    if (continuousUpper.length > 0) {
      plotData.push({
          x: continuousX,
          y: continuousLower,
          name: "Normal lower",
          fill: 'tozeroy',
          fillcolor: 'transparent',
          marker: {
            color: 'green'
          },
          legendgroup: 'normal bands',
          showlegend
        },
        {
          x: continuousX,
          y: continuousUpper,
          fill: "tonexty",
          fillcolor: normalBandColor,
          marker: {
            color: 'green'
          },
          name: "Normal upper",
          legendgroup: 'normal bands',
          showlegend
        })
    }

    plotData.push({
      x,
      y,
      type: 'scatter',
      mode: 'lines',
      marker: {color: 'black'},
      name: mapFeatureToCustomerFacing(feature),
    })

    const formatAlarmText = (alarm: Alarm): string => {
      return `Alarm type: ${alarm.alarm_type}<br>${alarm.description}`
    }
    const createPreviousAlarmXYAndText = (alarm: Alarm, numberOfPoints: number, yLowerRange: number, yUpperRange: number) => {
      // hack to make alarm lines "hoverable" with text
      const x: string[] = []
      const y: number[] = []
      let text: string[] = []
      for (let i = 0; i < numberOfPoints; i++) {
        x.push(formatDate(new Date(alarm.timestamp * 1000)));
        y.push(yLowerRange + (yUpperRange - yLowerRange) / numberOfPoints * i)
        text.push(formatAlarmText(alarm))

      }
      return {
        x, y, text
      }
    }

    const yLowerRangeWithMargins = yLowerRange - (yUpperRange - yLowerRange) * 0.25;
    const yUpperRangeWithMargins = yUpperRange + (yUpperRange - yLowerRange) * 0.25;
    let setResolvedLegend = false
    let setActiveLegend = false
    const existingAlarmData: Data[] = alarms.map((pa, index) => {
        let shouldSetLegend = pa.resolved ? !setResolvedLegend : !setActiveLegend
        pa.resolved ? setResolvedLegend = true : setActiveLegend = true
        return {
          ...createPreviousAlarmXYAndText(pa, 100, yLowerRangeWithMargins, yUpperRangeWithMargins),
          legendgroup: pa.resolved ? 'resolvedAlarms' : 'activeAlarms',
          mode: 'lines+markers',
          marker: {color: 'rgba(255, 255, 255, 0)', size: 16},
          showlegend: shouldSetLegend,
          name: pa.resolved ? 'Resolved alarms' : 'Active alarms',
          line: {
            dash: 'dash',
            width: 4,
            color: pa.resolved ? 'green' : pa.alarm_type === "A" ? 'red' : 'orange'
          },
          meta: {
            'alarm_id': pa.id
          }
        }
      }
    )
    plotData.push(...existingAlarmData)

    const to = graphEndTime ?? new Date();
    const from = new Date(to.getTime() - simpleGraphTimeConverter(selectedGraphTime ?? "Last 3 days") * 24 * 60 * 60 * 1000)

    return {
      data: plotData, layout: {
        title: `Feature: ${mapFeatureToCustomerFacing(feature)}`,
        hovermode: hovermode,
        autosize: true,
        margin: {l: 68},
        xaxis: {
          type: 'date',
          range: [formatDate(from), formatDate(to)]
        },
        yaxis: {
          // margins!
          range: [yLowerRangeWithMargins, yUpperRangeWithMargins]
        },
      },
      config: {
        modeBarButtonsToRemove: ["zoom2d", "pan2d", "lasso2d"],
        displaylogo: false
      }
    }
  }

  //Action handlers
  const navigateAlarmOnClick = (event: Plotly.PlotMouseEvent) => {
    if (event.points.length === 1 && navigateToAlarmOnClick) {
      let data = event.points[0].data
      if (hasMeta(data)) {
        let meta: any = getMeta(data)
        if ('alarm_id' in meta) {
          navigate("/alarm/" + meta.alarm_id)
        }
      }
    }
  }


  const handleGraphClick = (data: Readonly<PlotMouseEvent>): number | undefined => {
    if (typeof data.points[0].x === 'string') {
      return new Date(data.points[0].x).getTime() / 1000
    }
  }

  const handleGraphUpdateAfterClick = (feature: string, data: Data | undefined | void): void => {
    //TODO: Implement this properly.
  }

  return (
    <div className={className}>
      <div
        className="tw-text-sm tw-font-medium tw-text-gray-500 tw-ml-10">
        <p className="tw-flex tw-inline-flex">Model</p>
        <ul className="tw-flex tw-inline-flex tw-flex-wrap tw-mb-px tw-ml-5 tw-mt-4">
          {modelNames !== undefined && modelNames.size > 0 ? Array.from(modelNames).map(modelName => (
            <li className="tw-mr-2" key={modelName}>
              <Button type="button" size="small" onClick={() => {
                setSelectedModel(modelName)
              }}
                      variant="secondary"
                      styles={modelName !== selectedModel ? "tw-bg-transparent" : "tw-text-black"} key={modelName}>
                {mapModelNameToNiceName(modelName)}
              </Button>
            </li>)) : ""}
        </ul>
        {(selectedGraphTime !== undefined && onUpdateGraphTime !== undefined) &&
        <div className="tw-flex tw-inline-flex tw-text-center tw-right-20 tw-mt-4 tw-float-right">
          <SimpleDateSelect selectedTime={selectedGraphTime} setSelectedTime={(selectedTime) => {
            onUpdateGraphTime(selectedTime)
          }}/>
        </div>}

      </div>
      {selectedModel !== undefined && plots !== undefined && (Object.keys(plots).includes(selectedModel)) ? plots[selectedModel] :
        <p className="tw-flex tw-text-center tw-m-10">No anomaly detection results available for this model</p>}
    </div>
  )


}